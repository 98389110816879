import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function ContactSearch() {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <label>
          <b>Contact</b>
        </label>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Name</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="First Name"
              />
              <br />
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Last Name"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Email</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Work or Personal Email"
              />
              <br />
              <Form.Check type="checkbox" label="Match On Job History" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Location</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <div className="mb-3">
                <Form.Check type="radio" label="USA" />
                <Form.Check type="radio" label="Non-USA" />
                <Form.Check type="radio" label="Global" />
              </div>
              <div className="mb-3">
                <p>Office Type:</p>
                <Form.Check type="radio" label="HQ" />
                <Form.Check type="radio" label="Branch" />
                <Form.Check type="radio" label="Remote" />
              </div>
              <p>
                <Link>State</Link> | <Link>Zip Code</Link> |{" "}
                <Link>Metro Area</Link>
              </p>
              <h6>Enter one or more state names or abbreviations.</h6>
              <Form.Control type="text" id="searchbox" placeholder="States" />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </React.Fragment>
  );
}

export default ContactSearch;
