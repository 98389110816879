import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Cookies from "universal-cookie";

import { Formik } from "formik";

import logoImg from "../assets/img/logo.svg";
import { loginUsers } from "../staticData";

const logoCss = {
  textAlign: "center",
  width: "100%",
};

const logoImgCss = {
  height: "35px",
};

export default function Signin() {
  const cookie = new Cookies();
  const navigate = useNavigate();

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4" style={logoCss}>
            <img src={logoImg} alt="logo" style={logoImgCss} />
          </Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Welcome back! Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Username is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Password is required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                //alert(JSON.stringify(values, null, 2));
                const userName = values.email;
                const password = values.password;
                loginUsers?.map((data, index) => {
                  if (data.email == userName && data.password) {
                    localStorage.setItem("user_role", data.role);
                    localStorage.setItem("user_name", data.name);
                    if (data.role == "company") {
                      navigate("/company/dashboard");
                    } else if (data.role == "company-user") {
                      navigate("/company/subuser/dashboard");
                    } else if (data.role == "super-admin") {
                      navigate("/admin/dashboard");
                    } else if (data.role == "demo-user") {
                      navigate("/demo/user/dashboard");
                    }
                  }
                });
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <span style={{ color: "red" }}>
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password <Link to="/forgot-password">Forgot password?</Link>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span style={{ color: "red" }}>
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="btn-sign"
                >
                  Sign In
                </Button>

                <div className="divider">
                  <span>or sign in with</span>
                </div>

                <Row className="gx-2">
                  <Col>
                    <Button variant="" className="btn-facebook">
                      <i className="ri-facebook-fill"></i> Facebook
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="" className="btn-google">
                      <i className="ri-google-fill"></i> Google
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Card.Body>
        <Card.Footer>
          Don't have an account?{" "}
          <Link to="/pages/signup">Create an Account</Link>
        </Card.Footer>
      </Card>
    </div>
  );
}
