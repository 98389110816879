import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import Avatar from "../../Avatar";
import img1 from "../../../assets/img/img1.jpg";

function CreditRule(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Credit Rule List
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Link to="/admin/add-subscription" className="btn btn-primary">
                <i className="ri-file-add-line"></i> Add Rule
              </Link>
            </div>
          </div>
          
        </Row>
      </div>
    </React.Fragment>
  );
}

export default CreditRule;
