import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Button, Col, Dropdown, Nav, Row } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../../Avatar";

import img6 from "../../../assets/img/img6.jpg";
import img7 from "../../../assets/img/img7.jpg";
import img8 from "../../../assets/img/img8.jpg";
import img9 from "../../../assets/img/img9.jpg";
import img10 from "../../../assets/img/img10.jpg";
import img11 from "../../../assets/img/img11.jpg";
import img12 from "../../../assets/img/img12.jpg";
import img13 from "../../../assets/img/img13.jpg";
import img14 from "../../../assets/img/img14.jpg";
import img15 from "../../../assets/img/img15.jpg";
import img16 from "../../../assets/img/img16.jpg";
import img17 from "../../../assets/img/img17.jpg";
import img18 from "../../../assets/img/img18.jpg";
import img19 from "../../../assets/img/img19.jpg";

function CompanySubUserDataEnrichment() {
  useEffect(() => {
    document.body.classList.add("page-app");
    return () => {
      document.body.classList.remove("page-app");
    };
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="header-link"
    >
      {children}
    </Link>
  ));

  const contactGroup = [
    {
      group: "A",
      items: [
        {
          status: "offline",
          thumb: img11,
          name: "Abigaile Johnson",
          contact: "+1-234-567-890",
          selected: true,
        },
        {
          status: "online",
          thumb: img9,
          name: "Angeline Cantones",
          contact: "angeline@cantones.com",
        },
        {
          status: "online",
          initial: "primary",
          name: "Allan Rey Palban",
          contact: "allanrey@palban.com",
        },
        {
          status: "online",
          initial: "pink",
          name: "Aileen Palomares",
          contact: "aileen@palomares.com",
        },
      ],
    },
    {
      group: "B",
      items: [
        {
          status: "offline",
          thumb: img14,
          name: "Bellamy Newton",
          contact: "+1-890-1234-567",
        },
        {
          status: "online",
          initial: "purple",
          name: "Brenda Wilkins",
          contact: "+1-567-8901-234",
        },
        {
          status: "online",
          thumb: img17,
          name: "Bob Wise",
          contact: "bob@wise.com",
        },
      ],
    },
    {
      group: "C",
      items: [
        {
          status: "offline",
          thumb: img6,
          name: "Clifford Solis",
          contact: "+1-001-2236-445",
        },
      ],
    },
    {
      group: "D",
      items: [
        {
          status: "offline",
          thumb: img7,
          name: "Daniel Stewart",
          contact: "+1-201-2345-876",
        },
        {
          status: "offline",
          thumb: img10,
          name: "David Barrett",
          contact: "+1-323-5432-101",
        },
        {
          status: "offline",
          thumb: img8,
          name: "Dinah Webster",
          contact: "dinah@webster.com",
        },
        {
          status: "online",
          initial: "success",
          name: "Dindo Robertson",
          contact: "dindo@robertson.com",
        },
      ],
    },
    {
      group: "E",
      items: [
        {
          status: "online",
          thumb: img11,
          name: "Emily Blunt",
          contact: "emily@blunt.com",
        },
      ],
    },
    {
      group: "G",
      items: [
        {
          status: "online",
          initial: "info",
          name: "Georgie Kavkaz",
          contact: "georgie@kavkaz.com",
        },
        {
          status: "online",
          initial: "danger",
          name: "Guztavo Diaz",
          contact: "+2 345 6789 012",
        },
      ],
    },
    {
      group: "H",
      items: [
        {
          status: "online",
          thumb: img12,
          name: "Honey Lee",
          contact: "+9 012 3456 789",
        },
      ],
    },
    {
      group: "J",
      items: [
        {
          status: "offline",
          thumb: img19,
          name: "Joanna Cesar",
          contact: "+7 890 1234 567",
        },
        {
          status: "online",
          thumb: img18,
          name: "Jane Doe",
          contact: "+5 678 9012 345",
        },
        {
          status: "online",
          thumb: img16,
          name: "Janice Bentulan",
          contact: "janice@bentulan.com",
        },
      ],
    },
    {
      group: "M",
      items: [
        {
          status: "online",
          initial: "indigo",
          name: "Marie Recamada",
          contact: "marie@recamadas.org",
        },
      ],
    },
    {
      group: "P",
      items: [
        {
          status: "online",
          initial: "warning",
          name: "Pamela Anderson",
          contact: "pamela@andreson.com",
        },
      ],
    },
    {
      group: "S",
      items: [
        {
          status: "online",
          thumb: img15,
          name: "Samantha Perez",
          contact: "+3 456 7890 123",
        },
      ],
    },
    {
      group: "Z",
      items: [
        {
          status: "online",
          thumb: img13,
          name: "Zenaida Sanchez",
          contact: "+4 567 8901 234",
        },
      ],
    },
  ];

  // toggle contact sidebar in mobile
  const [isSidebarShow, setSidebarShow] = useState(false);

  const sidebarShow = () => {
    setSidebarShow(!isSidebarShow);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0"></h4>
          </div>
        </div>

        <div
          className={"contact-panel " + (isSidebarShow ? "sidebar-show" : "")}
        >
          <PerfectScrollbar className="contact-sidebar">
            <Nav className="nav-sidebar">
              <Nav.Link href="" className="active">
                <i className="ri-contacts-fill"></i> All Uploads
                <small>264</small>
              </Nav.Link>
              <Nav.Link href="">
                <i className="ri-archive-line"></i> History
              </Nav.Link>
              <Nav.Link href="">
                <i className="ri-delete-bin-line"></i> Trash
              </Nav.Link>
            </Nav>

            <hr />

            <label className="sidebar-label mb-2">Labels</label>
            <Nav className="nav-sidebar">
              <Nav.Link href="">
                <i className="ri-price-tag-line"></i> From Label 1
                <small>65</small>
              </Nav.Link>
              <Nav.Link href="">
                <i className="ri-price-tag-line"></i> From Label 2
                <small>24</small>
              </Nav.Link>
              <Nav.Link href="">
                <i className="ri-price-tag-line"></i> From Label 3
                <small>36</small>
              </Nav.Link>
            </Nav>
          </PerfectScrollbar>
          <div className="contact-body">
            <PerfectScrollbar className="">
              <Link id="contactClose" href="" className="contact-close">
                <i className="ri-close-fill"></i>
              </Link>
              <div className="d-sm-flex p-2 p-sm-4 p-md-2 p-xl-4">
                <div className="flex-fill">
                  <div class="drag-image">
                    <div class="icon">
                      <i class="fas fa-cloud-upload-alt"></i>
                    </div>
                    <h6>Drag & Drop File Here</h6>
                    <span>OR</span>

                    <div className="d-inline-block ms-3">
                      <Button className="btn btn-primary">
                        <i className="ri-add-fill"></i> Upload File
                      </Button>
                      <Button
                        style={{ backgroundColor: "#dc3545" }}
                        className="btn btn-primary ms-3 w-45"
                      >
                        Clear
                      </Button>
                      <br />
                      <h4 className="main-title mb-0">Upload File For Data Enrichment</h4>
                      <p>Allowed File Extentions .sql, .xls, .csv, .txt etc</p>
                    </div>

                    <input type="file" hidden />
                  </div>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanySubUserDataEnrichment;
