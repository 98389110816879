import React, { useState } from "react";

function InlineEdit(props) {
  const [isEditing, setIsEditing] = useState(false);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };
  return (
    <>
      {isEditing ? (
        <input
          autoFocus
          value={props.chore}
          onChange={props.onChange}
          onKeyPress={handleKeyPress}
          type="text"
          style={{width:"500px"}}
        />
      ) : (
        <span onClick={handleClick}>{props.chore}</span>
      )}
    </>
  );
}

export default InlineEdit;
