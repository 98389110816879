import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import Select from "react-select";

function OtherSearch(props) {
  const withDays = [
    { value: "1", label: "Within last 7 days" },
    { value: "2", label: "Within last 14 days" },
    { value: "3", label: "Within last 21 days" },
    { value: "4", label: "Within last 30 days" },
    { value: "5", label: "Custome" },
  ];

  return (
    <React.Fragment>
      <Row className="mb-3">
        <label>
          <b>Other</b>
        </label>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Rankings</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Check type="checkbox" label="Select All" />
              <Form.Check type="checkbox" label="100 Fastest Growing" />
              <Form.Check type="checkbox" label="Fast 500 Deloitte" />
              <Form.Check type="checkbox" label="Federal Contractors" />
              <Form.Check type="checkbox" label="Forbes Global 2,000" />
              <Form.Check type="checkbox" label="Fortune 500" />
              <Form.Check type="checkbox" label="Fortune 1,000" />
              <Form.Check type="checkbox" label="Funding Round - A" />
              <Form.Check type="checkbox" label="Funding Round - B" />
              <Form.Check type="checkbox" label="Funding Round - C" />
              <Form.Check type="checkbox" label="Funding Round - D" />
              <Form.Check type="checkbox" label="Funding Round - Seed" />
              <Form.Check type="checkbox" label="Inc 5,000" />
              <Form.Check type="checkbox" label="LinkedIn Top Startups 2021" />
              <Form.Check type="checkbox" label="SaaS 1,000" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Your Lists</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>
                <b>Company List</b>
              </p>
              <p>
                Select a list to narrow down your search to companies in that
                list only.
              </p>
              <Form.Check type="checkbox" label="Selected 0 company" />
              <Form.Check type="checkbox" label="DAAS-3756-2" />
              <hr />
              <p>
                <b>Contact List</b>
              </p>
              <p>
                Select a list to narrow down your search to contacts in that
                list only.
              </p>
              <Form.Check type="checkbox" label="Selected 0 contact" />
              <Form.Check type="checkbox" label="Contact List DAAS-4063" />
              <hr />
              <p>
                <b>Suppression List</b>
              </p>
              <p>Select a list to suppress companies or contacts.</p>
              <Form.Check type="checkbox" label="Selected 0 contact field" />
              <Form.Check type="checkbox" label="hareram kumar-sasa" />
              <hr />
              <p>
                <b>RoD List</b>
              </p>
              <Form.Check type="checkbox" label="Selected 0 contact" />
              <Form.Check type="checkbox" label="Regular Load test" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Results Include</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Check type="checkbox" label="Address" />
              <Form.Check type="checkbox" label="Any Phone" />
              <Form.Check type="checkbox" label="Any Direct Phone" />
              <Form.Check type="checkbox" label="Any Mobile Phone" />
              <Form.Check type="checkbox" label="Personal Email" />
              <Form.Check type="checkbox" label="Linkedin Url" />
              <Form.Check type="checkbox" label="Title" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Last Modified</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>
                This will filter contacts that have a change or are added since
                the given date.
              </p>
              <Select options={withDays} isSearchable={false} isClearable />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </React.Fragment>
  );
}

export default OtherSearch;
