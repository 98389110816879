import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Grid } from "gridjs-react";
import Select from "react-select";
import "./BuyCredits.css";

function BuyCredits(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [key, setKey] = useState("Companies");

  const planType = [
    {
      value: "basic",
      label: "Basic",
    },
    { value: "pro", label: "Pro" },
    { value: "enterprise", label: "Enterprise" },
  ];

  const typeDurations = [
    {
      value: "1",
      label: "Monthly",
    },
    { value: "2", label: "Yearly" },
  ];
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Buy Credits
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Link to="/company/dashboard" className="btn btn-primary">
                Back
              </Link>
            </div>
          </div>

          <Col xl="3"></Col>
          <Col xl="6">
            <Card className="card">
              <Card.Body>
                <Row>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Plan Type</Form.Label>
                      <Select
                        name="plan_type"
                        placeholder="Plan Type"
                        options={planType}
                        isSearchable={false}
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Type Duration</Form.Label>
                      <Select
                        name="type_duration"
                        placeholder="Type Duration"
                        options={typeDurations}
                        isSearchable={false}
                        isClearable
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>No of Credits</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="No of Credits"
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Amount"
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>No Of User License</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="No Of User License"
                        readOnly={true}
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Name on card</Form.Label>
                      <Form.Control type="text" placeholder=" Name on card " />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Card Number</Form.Label>
                      <Form.Control type="text" placeholder="Your card numbe" />
                    </div>
                  </Col>

                  <Col xl="4">
                    <div className="mb-3">
                      <Form.Label>Expiry Month</Form.Label>
                      <Form.Control type="number" placeholder="MM" />
                    </div>
                  </Col>

                  <Col xl="4">
                    <div className="mb-3">
                      <Form.Label>Expiry Year</Form.Label>
                      <Form.Control type="number" placeholder="YY" />
                    </div>
                  </Col>

                  <Col xl="4">
                    <div className="mb-3">
                      <Form.Label>CVV</Form.Label>
                      <Form.Control type="number" placeholder="CVV" />
                    </div>
                  </Col>

                  <Col xl="12">
                    <Button variant="primary" className="btn-sign me-2">
                      Pay Now
                    </Button>
                    <Button variant="danger" className="btn-sign">
                      Clear
                    </Button>
                  </Col>

                  <Col xl="12">
                    <div className="pay_wrapper">
                      <div className="outer-card">
                        <div className="forms">
                          <div className="input-items">
                            {" "}
                            <span>Card Number</span>{" "}
                            <input
                              placeholder=".... .... .... ...."
                              data-slots="."
                              data-accept="\d"
                              size="19"
                            />
                          </div>
                          <div className="input-items">
                            {" "}
                            <span>Name on card</span>{" "}
                            <input placeholder="Samuel Iscon" />{" "}
                          </div>
                          <div className="one-line">
                            <div className="input-items">
                              {" "}
                              <span>Expiry Date</span>{" "}
                              <input placeholder="mm/yyyy" data-slots="my" />
                            </div>
                            <div className="input-items">
                              {" "}
                              <span>CVV</span>{" "}
                              <input
                                placeholder="..."
                                data-slots="."
                                data-accept="\d"
                                size="3"
                              />
                            </div>
                          </div>
                          <div className="input-buttons d-inline-block">
                            {" "}
                            <a href="#">Pay Now</a>{" "}
                          </div>
                          <div className="input-buttons-clear d-inline-block">
                            {" "}
                            <a href="#">Clear</a>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3"></Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default BuyCredits;
