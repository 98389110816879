import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import SaleTabbing from "../uplead-tabbing/SaleTabbing";
import PerfectScrollbar from "react-perfect-scrollbar";

import { companyLists } from "../../staticData";
import { contactLists } from "../../staticData";

//Search Componets
import FirmographicsSearch from "../uplead-saved-search-contact-result/FirmographicsSearch";
import ContactRoleSearch from "../uplead-saved-search-contact-result/ContactRoleSearch";
import TechnographicsSearch from "../uplead-saved-search-contact-result/TechnographicsSearch";
import ContactSearch from "../uplead-saved-search-contact-result/ContactSearch";
import OtherSearch from "../uplead-saved-search-contact-result/OtherSearch";
import IntentSearch from "../uplead-saved-search-contact-result/IntentSearch";

function SalesHarmonyIntelligenceTemp(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [key, setKey] = useState("Companies");

  const [isSidebarShow, setSidebarShow] = useState(false);

  const [showSaveSearch, setSaveSearch] = useState(false);
  const [showAddAllToList, setAddAllToList] = useState(false);

  const handleShowSaveSearch = () => setSaveSearch(true);
  const handleCloseSaveSearch = () => setSaveSearch(false);

  const handleShowAddAllToList = () => setAddAllToList(true);
  const handleCloseAddAllToList = () => setAddAllToList(false);

  const companyListData = companyLists;
  const contactListData = contactLists;

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const selectOptions = [
    { value: "Amazon", label: "Amazon" },
    { value: "Flipcart", label: "Flipcart" },
    { value: "Tata", label: "Tata" },
  ];

  const mySearches = [
    {
      value: "Contact search revenue less than 10",
      label: "Contact search revenue less than 10",
    },
    { value: "Contact-1141-industry", label: "Contact-1141-industry" },
    { value: "Dell.com", label: "Dell.com" },
    { value: "Dev Machine", label: "Dev Machine" },
    { value: "Global", label: "Global" },
  ];

  const recommendedList = [
    {
      value: "100 Fastest Growing",
      label: "100 Fastest Growing",
    },
    { value: "Fast 500 Deloitte", label: "Fast 500 Deloitte" },
    { value: "Federal Contractors", label: "Federal Contractors" },
    { value: "Forbes Global 2,000", label: "Forbes Global 2,000" },
    { value: "Fortune 500", label: "Fortune 500" },

    { value: "Fortune 1,000", label: "Fortune 1,000" },
    { value: "Funding Round - A", label: "Funding Round - A" },
    { value: "Funding Round - B", label: "Funding Round - B" },
    { value: "Funding Round - C", label: "Funding Round - C" },

    { value: "Funding Round - D", label: "Funding Round - D" },
    { value: "Funding Round - Seed", label: "Funding Round - Seed" },
    { value: "Inc 5,000", label: "Inc 5,000" },

    {
      value: "LinkedIn Top Startups 2021",
      label: "LinkedIn Top Startups 2021",
    },
    { value: "SaaS 1,000", label: "SaaS 1,000" },
  ];

  const sidebarShow = () => {
    setSidebarShow(!isSidebarShow);
  };

  const sideSec = {
    height: "450px",
    overflowY: "auto",
  };

  const listsSec = {
    height: "350px",
    overflowY: "auto",
  };

  const calDataSec = {
    height: "525px",
    overflowY: "auto",
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="search-panel-custom-body main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Sales Intelligence
              </li>
            </ol>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <div className="revenue-item" key="">
              <div className="revenue-item-body">
                <span>Sales Intelligence Credits</span>
                <span className="ms-4">34 / 100,000</span>
                <span className="text-success">20%</span>
              </div>
              <ProgressBar now="20" />
            </div>
          </div>
        </div>

        <div
          className={
            "contact-panel-custom " + (isSidebarShow ? "sidebar-show" : "")
          }
        >
          <div className="saved-searches-bx contact-sidebar-custom">
            <Row className="mb-3">
              <Col xl="12" className="mt-4">
                <div className="d-grid mb-3">
                  <Form.Label>
                    <b>Saved Searches</b>
                  </Form.Label>
                  <Select
                    options={mySearches}
                    isSearchable={false}
                    isClearable
                    placeholder="Saved Searches"
                  />
                </div>
              </Col>
            </Row>
            <div className="yesy">
              <Row className="mb-3">
                <label>
                  <b>Company</b>
                </label>
                <p>Enter any company name or domain to search for. Press enter to start a new search term.{" "} </p>
                <Select
                  options={selectOptions}
                  isSearchable={false}
                  isMulti
                  placeholder="Company"
                />
              </Row>
              <FirmographicsSearch />
              <ContactRoleSearch />
              <TechnographicsSearch />
              <IntentSearch />
              <ContactSearch />
              <OtherSearch />
            </div>
            <br />
            <Row className="mb-3">
              <Col xl="12">
                <div>
                  <Form.Label>
                    <b>Default View:</b>
                  </Form.Label>
                </div>

                <div className="d-inline-block">
                  <Form.Check
                    type="radio"
                    checked="checked"
                    label="Companies"
                  />
                </div>
                <br />
                <div className="d-inline-block">
                  <Form.Check type="radio" label="Contacts" />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xl="6">
                <div className="d-grid mb-3">
                  <Button variant="primary">Search</Button>
                </div>
              </Col>
              <Col xl="6">
                <div className="d-grid mb-3">
                  <Button variant="danger">Clear</Button>
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xl="12">
                <div className="d-grid mb-3">
                  <Form.Label>
                    <b>Recommended Lists</b>
                  </Form.Label>
                  <Select
                    options={recommendedList}
                    isSearchable={false}
                    isClearable
                    placeholder="Recommended"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="contact-body-custom">
            <Link
              onClick={sidebarShow}
              href="#"
              className="contact-menu d-xl-none"
            >
              <i className="ri-arrow-left-line"></i>
            </Link>
            <Row className="g-3">
              <Col xl="12">
                <Col xl="12">
                  <div className="form-search py-2 mb-4">
                    <i className="ri-search-line"></i>
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Type Company Name or Domain"
                    />
                  </div>
                </Col>
                <br />
                <Col xl="12">
                  <Card className="card">
                    <Card.Body>
                      <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                      >
                        <Tab
                          eventKey="Companies"
                          title={
                            <span className="mail-search-result-icon">
                              <i className="ri-book-read-line" /> Companies
                            </span>
                          }
                        >
                          <Row className="mb-3">
                            <Col xl="4" lg="6" md="6" sm="6">
                              <Form.Check
                                type="radio"
                                label={
                                  <span>
                                    1,237 Human Verified{" "}
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>1,237 Human Verified</Tooltip>
                                      }
                                    >
                                      <i className="ri-question-fill" />
                                    </OverlayTrigger>
                                  </span>
                                }
                              />
                            </Col>
                            <Col xl="4" lg="6" md="6" sm="6">
                              <Form.Check
                                type="radio"
                                label={
                                  <span>
                                    4,530 Machine Verified{" "}
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          4,530 Machine Verified
                                        </Tooltip>
                                      }
                                    >
                                      <i className="ri-question-fill" />
                                    </OverlayTrigger>
                                  </span>
                                }
                              />
                            </Col>
                            <Col xl="2"></Col>
                            <Col xl="2"></Col>
                            <Col xl="2"></Col>
                          </Row>
                          <hr />
                          <Row className="mb-3">
                            <Col xl="6" lg="7" md="7">
                              <span className="d-inline-block">
                                0 Companies Selected
                              </span>
                              <div className="d-inline-block ms-2">
                                <Link
                                  to=""
                                  className="me-2"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Bulk Actions
                                </Link>
                                <Link
                                  to=""
                                  style={{ textDecoration: "underline" }}
                                  onClick={handleShowAddAllToList}
                                >
                                  Add All To List
                                </Link>
                              </div>
                            </Col>
                            <Col xl="6" lg="5" md="5" className="text-start text-lg-end text-md-end">
                              <Link
                                to=""
                                className="me-2"
                                style={{ textDecoration: "underline" }}
                                onClick={handleShowSaveSearch}
                              >
                                Save Search
                              </Link>
                              <Link
                                to=""
                                style={{ textDecoration: "underline" }}
                              >
                                Export All Companies
                              </Link>
                            </Col>
                          </Row>

                          <Col xl="12">
                            <Table
                              className="table-music mb-0 table"
                              responsive
                            >
                              <thead>
                                <tr>
                                  <th scope="row">
                                    <Form.Check type="checkbox" />
                                  </th>
                                  <th scope="col">Company</th>
                                  <th scope="col">Industry</th>
                                  <th scope="col">Revenue</th>
                                  <th scope="col">Size</th>
                                  <th scope="col">HQ</th>
                                  <th scope="col">Verified</th>
                                  <th scope="col">machine</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {companyListData?.map((data, index) => {
                                  return (
                                    <tr>
                                      <td scope="row">
                                        <Form.Check
                                          type="checkbox"
                                          value=""
                                          label={data?.id}
                                        />
                                      </td>
                                      <td>{data?.company}</td>
                                      <td>{data?.industry}</td>
                                      <td>{data?.revenue}</td>
                                      <td>{data?.size}</td>
                                      <td>{data?.hq}</td>
                                      <td>{data?.verified}</td>
                                      <td>{data?.machine}</td>
                                      <td>
                                        <Dropdown
                                          align="end"
                                          className="dropdown-file"
                                        >
                                          <Dropdown.Toggle as={CustomToggle}>
                                            <i className="ri-more-2-fill"></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              className="important"
                                              as={Link}
                                              to=""
                                            >
                                              <i className="ri-eye-fill"></i>{" "}
                                              View
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="details"
                                              as={Link}
                                              to=""
                                            >
                                              <i className="ri-pencil-fill"></i>{" "}
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="important"
                                              as={Link}
                                              to=""
                                            >
                                              <i className="ri-delete-bin-6-line"></i>{" "}
                                              Delete
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>

                            <br />
                            <Pagination className="justify-content-center mb-0">
                              <Pagination.Item disabled>
                                Previous
                              </Pagination.Item>
                              <Pagination.Item active>1</Pagination.Item>
                              <Pagination.Item>2</Pagination.Item>
                              <Pagination.Item>3</Pagination.Item>
                              <Pagination.Item>Next</Pagination.Item>
                            </Pagination>
                          </Col>
                        </Tab>

                        <Tab
                          eventKey="Contacts"
                          title={
                            <span className="mail-search-result-icon">
                              <i className="ri-contacts-book-2-fill" /> Contacts
                            </span>
                          }
                        >
                          <Row className="mb-3">
                            <Col xl="4" lg="6" md="6" sm="6">
                              <Form.Check
                                type="radio"
                                label={
                                  <span>
                                    1,237 Human Verified{" "}
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>1,237 Human Verified</Tooltip>
                                      }
                                    >
                                      <i className="ri-question-fill" />
                                    </OverlayTrigger>
                                  </span>
                                }
                              />
                            </Col>
                            <Col xl="4" lg="6" md="6" sm="6">
                              <Form.Check
                                type="radio"
                                label={
                                  <span>
                                    4,530 Machine Verified{" "}
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          4,530 Machine Verified
                                        </Tooltip>
                                      }
                                    >
                                      <i className="ri-question-fill" />
                                    </OverlayTrigger>
                                  </span>
                                }
                              />
                            </Col>
                            <Col xl="2"></Col>
                            <Col xl="2"></Col>
                            <Col xl="2"></Col>
                          </Row>
                          <hr />
                          <p className="main-title-text">You are viewing contacts which have not been human verified yet.</p>
                          <Row className="mb-3">
                            <Col xl="7" lg="7" md="7">
                              <span className="d-inline-block">
                                0 Contacts Selected
                              </span>
                              <div className="d-inline-block ms-2">
                                <Link
                                  to=""
                                  className="me-2"
                                  style={{ textDecoration: "underline" }}
                                >
                                  Bulk Actions
                                </Link>
                                <Link style={{ textDecoration: "underline" }}>
                                  Verify All Contacts
                                </Link>
                              </div>
                            </Col>

                            <Col xl="5" lg="5" md="5" className="text-start text-lg-end text-md-end">
                              <Link
                                to=""
                                className="me-2"
                                style={{ textDecoration: "underline" }}
                              >
                                Search Saved
                              </Link>
                              <Link style={{ textDecoration: "underline" }}>
                                Add All to List
                              </Link>
                            </Col>
                          </Row>

                          <Col xl="12">
                            <Table
                              className="table-music mb-0 table"
                              responsive
                            >
                              <thead>
                                <tr>
                                  <th scope="col">
                                    <Form.Check type="checkbox" />
                                  </th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Job Title</th>
                                  <th scope="col">Level</th>
                                  <th scope="col">Dept.</th>
                                  <th scope="col">Company</th>
                                  <th scope="col">Location</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {contactListData?.map((data, index) => {
                                  return (
                                    <tr>
                                      <td scope="row">
                                        <Form.Check
                                          type="checkbox"
                                          label={data?.id}
                                        />
                                      </td>
                                      <td>{data?.name}</td>
                                      <td>{data?.job_title}</td>
                                      <td>{data?.level}</td>
                                      <td>{data?.dept}</td>
                                      <td>
                                        <Link to="">{data?.company}</Link>
                                      </td>
                                      <td>{data?.location}</td>
                                      <td>
                                        <Dropdown
                                          align="end"
                                          className="dropdown-file"
                                        >
                                          <Dropdown.Toggle as={CustomToggle}>
                                            <i className="ri-more-2-fill"></i>
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              className="important"
                                              as={Link}
                                              to=""
                                            >
                                              <i className="ri-eye-fill"></i>{" "}
                                              View
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="details"
                                              as={Link}
                                              to=""
                                            >
                                              <i className="ri-pencil-fill"></i>{" "}
                                              Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="important"
                                              as={Link}
                                              to=""
                                            >
                                              <i className="ri-delete-bin-6-line"></i>{" "}
                                              Delete
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <br />
                            <Pagination className="justify-content-center mb-0">
                              <Pagination.Item disabled>
                                Previous
                              </Pagination.Item>
                              <Pagination.Item active>1</Pagination.Item>
                              <Pagination.Item>2</Pagination.Item>
                              <Pagination.Item>3</Pagination.Item>
                              <Pagination.Item>Next</Pagination.Item>
                            </Pagination>
                          </Col>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SalesHarmonyIntelligenceTemp;
