import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import Avatar from "../../Avatar";

import paymentFailds from "../../../assets/img/payment-failds.png";

function PaymentFailed() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3 justify-content-center">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/payment-lists">Payment Lists</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payment Failed
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>

          <Col xl="8">
            <Card className="card">
              <Card.Body>
                <Row className="mt-2">
                  <Col xs="12" className="text-end text-secondary">
                    <div className="text-center">
                      <img
                        src={paymentFailds}
                        style={{ width: "10%" }}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="12">
                    <div className="text-center">
                      <h3>Your payment failed</h3>
                      <h4>Please try again</h4>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default PaymentFailed;
