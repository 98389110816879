import React from "react";
import { Link } from "react-router-dom";

import Img2 from "../assets/img/img11.jpg";
import Img3 from "../assets/img/img14.jpg";
import Img4 from "../assets/img/img15.jpg";

import Img1 from "../assets/img/avatar.png";

// eslint-disable-next-line
export default Notification = [
  {
    avatar: <img src={Img1} alt="" />,
    text: (
      <React.Fragment>
        <strong>User 1</strong> has shared List Name 1 with you.
      </React.Fragment>
    ),
    date: "Aug 20 08:55am",
    status: "",
  },
  {
    avatar: <img src={Img1} alt="" />,
    text: (
      <React.Fragment>
        <strong>User 2</strong> has shared List Name 3 with you.
      </React.Fragment>
    ),
    date: "Aug 20 08:55am",
    status: "",
  },
  {
    avatar: <img src={Img1} alt="" />,
    text: (
      <React.Fragment>
        <strong>Your</strong> Sales ROD Report Name 1 is ready.
      </React.Fragment>
    ),
    date: "Aug 20 08:55am",
    status: "",
    status_old: "online",
  },
];
