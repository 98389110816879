import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import Footer from "../../../layouts/Footer";

function EditSubscription(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/subscription-lists">Subscription List</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Subscription
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Link to="/admin/subscription-lists" className="btn btn-primary">
                Back
              </Link>
            </div>
          </div>

          <Col xl="3"></Col>
          <Col xl="6">
            <Card className="card">
              <Card.Body>
                <Row>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>
                        Plan Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Plan Name" />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>
                        Type <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option value="">Type</option>
                        <option value="1">Monthly</option>
                        <option value="2">Yearly</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>
                        No of Credits <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter No of Credits"
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>
                        Price <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter No of Credits"
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Sale Price</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter No of Credits"
                      />
                    </div>
                  </Col>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>
                        No Of User License{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter No Of User License"
                      />
                    </div>
                  </Col>

                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <Form.Check
                        type="switch"
                        style={{ fontSize: "20px" }}
                        label=""
                      />
                    </div>
                  </Col>
                </Row>
                <Button variant="primary" className="btn-sign">
                  Create
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3"></Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default EditSubscription;
