const adminMenus = [
  {
    label: "Dashboard",
    link: "/admin/dashboard",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Users",
    icon: "ri-account-circle-line",
    submenu: [
      {
        label: "User List",
        link: "/admin/users-lists",
      },
      {
        label: "Add User",
        link: "/admin/add-user",
      },
    ],
  },
  {
    label: "Subscriptions",
    icon: "ri-shopping-bag-3-line",
    submenu: [
      {
        label: "Subscription List",
        link: "/admin/subscription-lists",
      },
      {
        label: "Add Subscription",
        link: "/admin/add-subscription",
      },
    ],
  },
  {
    label: "Payments",
    icon: "ri-list-check-2",
    submenu: [
      {
        label: "Payment List",
        link: "/admin/payment-lists",
      },
    ],
  },
  {
    label: "Search",
    icon: "ri-file-search-line",
    submenu: [
      {
        label: "Sales Intelligence",
        link: "/dashboard/uplead-intelligence",
      },
      {
        label: "Sales ROD",
        link: "/dashboard/uplead-rod",
      },
      {
        label: "Saved Searches",
        link: "/dashboard/uplead-saved-search",
      },
      {
        label: "Saved Lists",
        link: "dashboard/uplead-lists",
      },
      {
        label: "Bounce Intelligence",
        link: "/dashboard/uplead-bounce",
      },
      {
        label: "Visitor Intelligence",
        link: "/dashboard/uplead-visitor",
      },
      {
        label: "Data Enrichment",
        link: "/admin/data/enrichment",
      },
    ],
  },
  {
    label: "Human Verified",
    link: "/admin/human-verified",
    icon: "ri-file-search-line",
  },
  {
    label: "Logs",
    link: "/admin/logs",
    icon: "ri-record-mail-line",
  },
  {
    label: "Site Setting",
    icon: "ri-file-code-line",
    submenu: [
      {
        label: "Global Site Settings",
        link: "/admin/settings",
      },
      {
        label: "Notifications",
        link: "/admin/settings",
      },
      {
        label: "Other",
        link: "/admin/settings",
      },
    ],
  },
];

//Company Menu
const companyMenu = [
  {
    label: "Dashboard",
    link: "/company/dashboard",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Sub-Users",
    icon: "ri-account-circle-line",
    submenu: [
      {
        label: "User List",
        link: "company/company-user-lists",
      },
      {
        label: "Add User",
        link: "company/add-company-user",
      },
    ],
  },
  {
    label: "Saved Searches",
    link: "dashboard/uplead-saved-search",
    icon: "ri-shopping-bag-3-line",
  },
  {
    label: "Search",
    icon: "ri-file-search-line",
    submenu: [
      {
        label: "Sales Intelligence",
        link: "/dashboard/uplead-intelligence",
      },
      {
        label: "Sales ROD",
        link: "/dashboard/uplead-rod",
      },
      {
        label: "Saved Searches",
        link: "/dashboard/uplead-saved-search",
      },
      {
        label: "Saved Lists",
        link: "dashboard/uplead-lists",
      },
      {
        label: "Bounce Intelligence",
        link: "/dashboard/uplead-bounce",
      },
      {
        label: "Visitor Intelligence",
        link: "/dashboard/uplead-visitor",
      },
      {
        label: "Data Enrichment",
        link: "/company/data/enrichment",
      },
    ],
  },
  {
    label: "Human Verified",
    link: "company/human-verified",
    icon: "ri-file-search-line",
  },
  {
    label: "Logs",
    link: "/company/logs",
    icon: "ri-record-mail-line",
  },
  {
    label: "Purchased History",
    icon: "ri-list-check-2",
    submenu: [
      {
        label: "Payment List",
        link: "/company/purchased-history",
      },
    ],
  },
  {
    label: "Setting",
    link: "/company/settings",
    icon: "ri-pie-chart-2-line",
  },
];

//Company Sub Menu
const companySubUserMenu = [
  {
    label: "Dashboard",
    link: "company/subuser/dashboard",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Data Enrichment",
    link: "/company/subuser/data/enrichment",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Plan",
    icon: "ri-account-circle-line",
    submenu: [
      {
        label: "Lists",
        link: "company/subuser/plan",
      },
    ],
  },
  {
    label: "Search",
    icon: "ri-file-search-line",
    submenu: [
      {
        label: "Saved Searches",
        link: "/dashboard/uplead-saved-search",
      },
      {
        label: "Saved Lists",
        link: "dashboard/uplead-lists",
      },
    ],
  },
  {
    label: "Logs",
    link: "/company/subuser/logs",
    icon: "ri-record-mail-line",
  },
];

//Demo User
const demoUserMenu = [
  {
    label: "Dashboard",
    link: "demo/user/dashboard",
    icon: "ri-pie-chart-2-line",
  },
  {
    label: "Saved Searches",
    link: "dashboard/uplead-saved-search",
    icon: "ri-shopping-bag-3-line",
  },
  {
    label: "Logs",
    link: "/demo/user/logs",
    icon: "ri-record-mail-line",
  },
];

//Other Menu
const otherUserMenu = [
  // {
  //   label: "Research on Demand",
  //   link: "/dashboard/uplead-rod",
  //   icon: "ri-sticky-note-line",
  // },
  {
    label: "Support",
    link: "/support",
    icon: "ri-align-center",
  }
];

//Search Panel
const searchPanelMenu = [
  {
    label: "Sales Intelligence",
    link: "/dashboard/uplead-intelligence",
    icon: "ri-home-4-line",
  },
  {
    label: "Sales ROD",
    link: "/dashboard/uplead-rod",
    icon: "ri-sticky-note-line",
  },
  {
    label: "Saved Searches",
    link: "/dashboard/uplead-saved-search",
    icon: "ri-align-center",
  },
  {
    label: "Lists",
    link: "/dashboard/uplead-lists",
    icon: "ri-line-height",
  },
  {
    label: "Bounce Intelligence",
    link: "/dashboard/uplead-bounce",
    icon: "ri-indent-increase",
  },
  {
    label: "Visitor Intelligence",
    link: "/dashboard/uplead-visitor",
    icon: "ri-coupon-line",
  },
];


export {
  adminMenus,
  companyMenu,
  companySubUserMenu,
  demoUserMenu,
  otherUserMenu,
  searchPanelMenu,
};
