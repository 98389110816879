import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import SaleTabbing from "../uplead-tabbing/SaleTabbing";
import PerfectScrollbar from "react-perfect-scrollbar";

import { companyLists } from "../../staticData";
import { contactLists } from "../../staticData";

//Search Componets
import FirmographicsSearch from "./FirmographicsSearch";
import ContactRoleSearch from "./ContactRoleSearch";
import TechnographicsSearch from "./TechnographicsSearch";
import ContactSearch from "./ContactSearch";
import OtherSearch from "./OtherSearch";
import IntentSearch from "./IntentSearch";

function SalesHarmonySavedSearchContactResult(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [key, setKey] = useState("Companies");
  const [showSaveSearch, setSaveSearch] = useState(false);
  const [showAddAllToList, setAddAllToList] = useState(false);

  const handleShowSaveSearch = () => setSaveSearch(true);
  const handleCloseSaveSearch = () => setSaveSearch(false);

  const handleShowAddAllToList = () => setAddAllToList(true);
  const handleCloseAddAllToList = () => setAddAllToList(false);

  const companyListData = companyLists;
  const contactListData = contactLists;

  const selectOptions = [
    { value: "Amazon", label: "Amazon" },
    { value: "Flipcart", label: "Flipcart" },
    { value: "Tata", label: "Tata" },
  ];

  const mySearches = [
    {
      value: "Contact search revenue less than 10",
      label: "Contact search revenue less than 10",
    },
    { value: "Contact-1141-industry", label: "Contact-1141-industry" },
    { value: "Dell.com", label: "Dell.com" },
    { value: "Dev Machine", label: "Dev Machine" },
    { value: "Global", label: "Global" },
  ];

  const sideSec = {
    height: "450px",
    overflowY: "auto",
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <SaleTabbing />
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Uplead Search Result
                </li>
              </ol>
            </div>
          </div>

          <Col xl="3">
            <PerfectScrollbar className="contact-sidebar11">
              <Select options={mySearches} isSearchable={false} isClearable />
              <br />
              <div className="yesy">
                <Row className="mb-3">
                  <label>
                    <b>Company</b>
                  </label>
                  <p>
                    Enter any company name or domain to search for. Press enter
                    to start a new search term.{" "}
                  </p>
                  <Select
                    options={selectOptions}
                    isSearchable={false}
                    isMulti
                  />
                </Row>
                <FirmographicsSearch />
                <ContactRoleSearch />
                <TechnographicsSearch />
                <IntentSearch />
                <ContactSearch />
                <OtherSearch />
              </div>
              <br />
              <Row className="mb-3">
                <Col xl="4">Default View:</Col>
                <Col xl="4">
                  <Form.Check
                    type="radio"
                    label="Companies"
                    checked="checked"
                  />
                </Col>
                <Col xl="4">
                  <Form.Check type="radio" label="Contacts" />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col xl="6">
                  <div className="d-grid mb-3">
                    <Button variant="primary">Search</Button>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="d-grid mb-3">
                    <Button variant="danger">Clear</Button>
                  </div>
                </Col>
              </Row>
            </PerfectScrollbar>
          </Col>
          <Col xl="9">
            <Card className="card">
              <Card.Body>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3"
                >
                  <Tab
                    eventKey="Companies"
                    title={
                      <span className="mail-search-result-icon">
                        <i className="ri-book-read-line" /> Companies
                      </span>
                    }
                  >
                    <Row className="mb-3">
                      <Col xl="3">
                        <Form.Check type="radio" label="1,237 Human Verified" />
                      </Col>
                      <Col xl="3">
                        <Form.Check
                          type="radio"
                          label="4,530 Machine Verified"
                        />
                      </Col>
                      <Col xl="2"></Col>
                      <Col xl="2"></Col>
                      <Col xl="2"></Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col xl="6">
                        <span className="d-inline-block">
                          0 Companies Selected
                        </span>
                        <div className="d-inline-block ms-2">
                          <Link
                            to=""
                            className="me-2"
                            style={{ textDecoration: "underline" }}
                          >
                            Bulk Actions
                          </Link>
                          <Link
                            to=""
                            style={{ textDecoration: "underline" }}
                            onClick={handleShowAddAllToList}
                          >
                            Add All To List
                          </Link>
                        </div>
                      </Col>
                      <Col xl="6" className="ms-auto text-end">
                        <Link
                          to=""
                          className="me-2"
                          style={{ textDecoration: "underline" }}
                          onClick={handleShowSaveSearch}
                        >
                          Save Search
                        </Link>
                        <Link to="" style={{ textDecoration: "underline" }}>
                          Export All Companies
                        </Link>
                      </Col>
                    </Row>

                    <Col xl="12">
                      <Card className="card">
                        <Card.Body>
                          <Table className="table-music mb-0 table" responsive>
                            <thead>
                              <tr>
                                <th scope="row">
                                  <Form.Check type="checkbox" />
                                </th>
                                <th scope="col">Company</th>
                                <th scope="col">Industry</th>
                                <th scope="col">Revenue</th>
                                <th scope="col">Size</th>
                                <th scope="col">HQ</th>
                                <th scope="col">Verified</th>
                                <th scope="col">machine</th>
                              </tr>
                            </thead>
                            <tbody>
                              {companyListData?.map((data, index) => {
                                return (
                                  <tr>
                                    <td scope="row">
                                      <Form.Check
                                        type="checkbox"
                                        value=""
                                        label={data?.id}
                                      />
                                    </td>
                                    <td>{data?.company}</td>
                                    <td>{data?.industry}</td>
                                    <td>{data?.revenue}</td>
                                    <td>{data?.size}</td>
                                    <td>{data?.hq}</td>
                                    <td>{data?.verified}</td>
                                    <td>{data?.machine}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          <br />
                          <Pagination className="justify-content-center mb-0">
                            <Pagination.Item disabled>Previous</Pagination.Item>
                            <Pagination.Item active>1</Pagination.Item>
                            <Pagination.Item>2</Pagination.Item>
                            <Pagination.Item>3</Pagination.Item>
                            <Pagination.Item>Next</Pagination.Item>
                          </Pagination>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Tab>

                  <Tab
                    eventKey="Contacts"
                    title={
                      <span className="mail-search-result-icon">
                        <i className="ri-contacts-book-2-fill" /> Contacts
                      </span>
                    }
                  >
                    <Row className="mb-3">
                      <Col xl="3">
                        <Form.Check type="radio" label="1,237 Human Verified" />
                      </Col>
                      <Col xl="3">
                        <Form.Check
                          type="radio"
                          label="4,530 Machine Verified"
                        />
                      </Col>
                      <Col xl="2"></Col>
                      <Col xl="2"></Col>
                      <Col xl="2"></Col>
                    </Row>
                    <hr />
                    <p className="main-title-text">
                      You are viewing contacts which have not been human
                      verified yet.
                    </p>
                    <Row className="mb-3">
                      <Col xl="6">
                        <span className="d-inline-block">
                          0 Contacts Selected
                        </span>
                        <div className="d-inline-block ms-2">
                          <Link
                            to=""
                            className="me-2"
                            style={{ textDecoration: "underline" }}
                          >
                            Bulk Actions
                          </Link>
                          <Link style={{ textDecoration: "underline" }}>
                            Verify All Contacts
                          </Link>
                        </div>
                      </Col>

                      <Col xl="6" className="ms-auto text-end">
                        <Link
                          to=""
                          className="me-2"
                          style={{ textDecoration: "underline" }}
                        >
                          Search Saved
                        </Link>
                        <Link style={{ textDecoration: "underline" }}>
                          Add All to List
                        </Link>
                      </Col>
                    </Row>

                    <Col xl="12">
                      <Card className="card">
                        <Card.Body>
                          <Table className="table-music mb-0 table" responsive>
                            <thead>
                              <tr>
                                <th scope="col">
                                  <Form.Check type="checkbox" />
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Job Title</th>
                                <th scope="col">Level</th>
                                <th scope="col">Dept.</th>
                                <th scope="col">Company</th>
                                <th scope="col">Location</th>
                              </tr>
                            </thead>
                            <tbody>
                              {contactListData?.map((data, index) => {
                                return (
                                  <tr>
                                    <td scope="row">
                                      <Form.Check
                                        type="checkbox"
                                        label={data?.id}
                                      />
                                    </td>
                                    <td>{data?.name}</td>
                                    <td>{data?.job_title}</td>
                                    <td>{data?.level}</td>
                                    <td>{data?.dept}</td>
                                    <td>
                                      <Link to="">{data?.company}</Link>
                                    </td>
                                    <td>{data?.location}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          <br />
                          <Pagination className="justify-content-center mb-0">
                            <Pagination.Item disabled>Previous</Pagination.Item>
                            <Pagination.Item active>1</Pagination.Item>
                            <Pagination.Item>2</Pagination.Item>
                            <Pagination.Item>3</Pagination.Item>
                            <Pagination.Item>Next</Pagination.Item>
                          </Pagination>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>

      <Modal
        show={showAddAllToList}
        onHide={handleCloseAddAllToList}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add All To List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form>
            <Row className="mb-3">
              <Col sm="12">
                <Form.Label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  Name
                </Form.Label>
                <Form.Control
                  type="taxt"
                  id="inputEmail3"
                  placeholder="Pleasse Enter Name"
                />
              </Col>
            </Row>
          </Form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddAllToList}>
            Close
          </Button>
          <Button variant="primary">Add</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSaveSearch}
        onHide={handleCloseSaveSearch}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col sm="12">
                <Form.Label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  Name
                </Form.Label>
                <Form.Control
                  type="taxt"
                  id="inputEmail3"
                  placeholder="Pleasse Enter Name"
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSaveSearch}>
            Close
          </Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SalesHarmonySavedSearchContactResult;
