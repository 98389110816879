import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";

function ChangePassword(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [key, setKey] = useState("Companies");
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <a href="#">Dashboard</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Change Password
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <a href="#" className="btn btn-primary">
                Back
              </a>
            </div>
          </div>

          <Col xl="3"></Col>
          <Col xl="6">
            <Card className="card">
              <Card.Body>
                <div className="mb-3">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your current password"
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your new password"
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your confirm password"
                  />
                </div>
                <Button variant="primary" className="btn-sign">
                  Change
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3"></Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ChangePassword;
