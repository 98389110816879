import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Grid } from "gridjs-react";
import Select from "react-select";
import CompanyForm from "./CompanyForm";

function CreateCompanyUser(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [key, setKey] = useState("Companies");
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/company/company-user-lists">User List</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Add User
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Link
                to="/company/company-user-lists"
                className="btn btn-primary"
              >
                Back
              </Link>
            </div>
          </div>

          <Col xl="3"></Col>
          <Col xl="6">
            <Card className="card">
              <Card.Body>
                <CompanyForm />
                <Button variant="primary" className="btn-sign">
                  Create
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3"></Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CreateCompanyUser;
