import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { categoriesData } from "../../staticData";

function TechnographicsSearch() {
  //Level
  const categoryData = categoriesData;

  const sideSec = {
    height: "450px",
    overflowY: "auto",
  };

  return (
    <React.Fragment>
      <Row className="mb-3">
        <label>
          <b>Technographics</b>
        </label>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Products</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p><b>Search by Product</b></p>
              <p>Returns companies that have any of the selected products.</p>
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Technographic Product"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Providers</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>
                <b>Search by Provider</b>
              </p>
              <p>
                Returns companies that have a product from any of the selected
                provider.
              </p>
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Technographic Providers"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Categories</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1" style={sideSec}>
              <p>
                <b>Category</b>
              </p>
              <p>
                Returns companies that have a product in any of the selected
                categories.
              </p>
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Enter Category Name"
              />
              <br />
              <Form.Check type="checkbox" label="Select All" />
              {categoryData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </React.Fragment>
  );
}

export default TechnographicsSearch;
