import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Nav, Card, Button, Table } from "react-bootstrap";
import Footer from "../../../layouts/Footer";
import Avatar from "../../Avatar";
import Header from "../../../layouts/Header";

import img1 from "../../../assets/img/avatar.png";

function CompanySettings() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Global Site Settings
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0"></div>
        </div>

        <Nav className="nav-line mb-4">
          <Nav.Link href="" className="active">
            General
          </Nav.Link>
          <Nav.Link href="">Notifications</Nav.Link>
          <Nav.Link href="">Language &amp; Region</Nav.Link>
          <Nav.Link href="">Accessibility</Nav.Link>
          <Nav.Link href="">Advanced</Nav.Link>
        </Nav>

        <Card className="card-settings">
          <Card.Header>
            <Card.Title>Company Information</Card.Title>
            <Card.Text>
              
            </Card.Text>
          </Card.Header>
          <Card.Body className="p-0">
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Company Name</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="Enter company name" />
                </Col>
              </Row>
            </div>
            <div className="setting-item">
              <Row className="g-2">
                <Col md="5">
                  <h6>Tagline</h6>
                </Col>
                <Col md>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Enter tagline"
                  />
                </Col>
              </Row>
            </div>
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Company Logo</h6>
                </Col>
                <Col md>
                  <Button variant="" className="btn-white">
                    Upload logo
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="setting-item">
              <Row className="g-2 align-items-center">
                <Col md="5">
                  <h6>Company Email</h6>
                </Col>
                <Col md>
                  <Form.Control type="text" placeholder="Enter email address" />
                  <Form.Check
                    type="checkbox"
                    label="Subscribe to Newsletter"
                    className="mt-3"
                  />
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>

        <Card className="card-settings mt-4">
          <Card.Header>
            <Card.Title>Member Access</Card.Title>
            <Card.Text>
              
            </Card.Text>
          </Card.Header>
          <Card.Body className="p-0">
            <div className="setting-item">
              <Row className="g-2">
                <Col md="5">
                  <h6>Superusers</h6>
                  <p></p>
                </Col>
                <Col md>
                  <Table className="mb-0" responsive>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Last Active</th>
                        <th scope="col">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          img: img1,
                          name: "Adrian Moniño",
                          date: "May 20, 2023",
                        },
                        {
                          img: img1,
                          name: "Socrates Itumay",
                          date: "Apr 10, 2023",
                        },
                        {
                          img: img1,
                          name: "Rhea Castañares",
                          date: "Feb 16, 2023",
                        },
                        {
                          img: img1,
                          name: "Marianne Audrey",
                          date: "Feb 10, 2023",
                        },
                        {
                          img: img1,
                          name: "Reynante Labares",
                          date: "Dec 20, 2022",
                        },
                      ].map((user, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Avatar size="sm" img={user.img} />
                              <span>{user.name}</span>
                            </div>
                          </td>
                          <td>{user.date}</td>
                          <td>
                            <Nav as="nav">
                              <Link to="">
                                <i className="ri-pencil-line"></i>
                              </Link>
                              <Link to="">
                                <i className="ri-delete-bin-line"></i>
                              </Link>
                            </Nav>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanySettings;
