import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Grid } from "gridjs-react";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

function CompanyPurchasedHistory(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [filterBy, setFilterBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  //Filter Function
  const filterPayment = (e) => {
    const postdata = {
      keyword: filterBy,
      start_date: startDate,
      end_date: endDate,
    };
    console.log(postdata);
  };

  //Reset
  const resetFilterPayment = (e) => {
    const postdata = {
      keyword: "",
      start_date: "",
      end_date: "",
    };
    console.log(postdata);
    setFilterBy("");
    setStartDate("");
    setEndDate("");
  };

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const selectionRangeData = (e) => {};

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Purchased History
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>

          <Col xl="12">
            <Card className="card">
              <Card.Body>
                <Row>
                  <Col xl="3" className="mb-3">
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Filter by Transaction ID"
                      onChange={(e) => setFilterBy(e.target.value)}
                      value={filterBy}
                    />
                  </Col>
                  <Col xl="3" className="mb-3">
                    <DateRangePicker>
                      <Form.Control
                        type="text"
                        id="date_range"
                        placeholder="Select date range"
                        onChange=""
                        value=""
                      />
                    </DateRangePicker>
                  </Col>
                  {/* <Col xl="2" className="mb-3">
                    <ReactDatePicker
                      dateFormat="MMMM dd, yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="form-control asd"
                      placeholderText="Select Start Date"
                    />
                  </Col>
                  <Col xl="2" className="mb-3">
                    <ReactDatePicker
                      dateFormat="MMMM dd, yyyy"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      className="form-control asd"
                      placeholderText="Select End Date"
                    />
                  </Col> */}
                  <Col xl="2" className="mb-3">
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={filterPayment}
                    >
                      Filter
                    </Button>
                    <Button variant="danger" onClick={resetFilterPayment}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="12">
            <Table className="table-music mb-0 table" responsive>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Plan Details</th>
                  <th scope="col">User Details</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Payment Date</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>Purchase from #10322</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>
                    Amazon
                    <span className="d-block fs-xs text-success">Company</span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-success">
                      Completed
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/view-purchased-history"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">2</td>
                  <td>Process refund to #00910</td>
                  <td>
                    Basic
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>
                    Amazon
                    <span className="d-block fs-xs text-success">Company</span>
                  </td>

                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-warning">
                      Processing
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/view-purchased-history"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">3</td>
                  <td>Payment failed #087651</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>
                    Mariya
                    <span className="d-block fs-xs text-success">
                      Company Sub User
                    </span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-danger">Declined</span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/view-purchased-history"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">4</td>
                  <td>Purchase from #10322</td>

                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>
                    Amazon
                    <span className="d-block fs-xs text-success">Company</span>
                  </td>

                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-success">
                      Completed
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/view-purchased-history"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">5</td>
                  <td>Process refund to #00910</td>

                  <td>
                    Basic
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>
                    Jeams
                    <span className="d-block fs-xs text-success">
                      Company Sub User
                    </span>
                  </td>

                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-warning">
                      Processing
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/view-purchased-history"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">6</td>
                  <td>Payment failed #087651</td>

                  <td>
                    Enterprise
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>
                    Jeams
                    <span className="d-block fs-xs text-success">
                      Company Sub User
                    </span>
                  </td>

                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-danger">Declined</span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/view-purchased-history"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>

          {/* <Col xl="12">
            <Card className="card-one">
              <Card.Body className="p-0">
                <ul className="people-group">
                  <li className="people-item">
                    <div className="avatar">1.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-teal">
                        <i className="ri-shopping-cart-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Purchase from #10322 | </Link>
                        <Link to="">Pro Plan</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">+$250.00</div>
                      <span className="d-block fs-xs text-success">
                        Completed
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/company/view-purchased-history"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">2.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-info">
                        <i className="ri-coins-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Process refund to #00910 | </Link>
                        <Link to="">Basic Plan</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">-$250.00</div>
                      <span className="d-block fs-xs text-warning">
                        Processing
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/company/view-purchased-history"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">3.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-secondary">
                        <i className="ri-secure-payment-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Payment failed #087651 | </Link>
                        <Link to="">Pro Plan</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">$150.20</div>
                      <span className="d-block fs-xs text-danger">
                        Declined
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/company/view-purchased-history"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Pagination className="justify-content-center mb-0">
                  <Pagination.Item disabled>Previous</Pagination.Item>
                  <Pagination.Item active>1</Pagination.Item>
                  <Pagination.Item>2</Pagination.Item>
                  <Pagination.Item>3</Pagination.Item>
                  <Pagination.Item>Next</Pagination.Item>
                </Pagination>
              </Card.Footer>
            </Card>
          </Col> */}
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanyPurchasedHistory;
