import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import Avatar from "../../Avatar";
import img1 from "../../../assets/img/avatar.png";

function UserForm(props) {
  return (
    <React.Fragment>
      <Row>
        <Col xs="3">
          <div className="mb-3">
            <Form.Label></Form.Label>
            <Avatar img={img1} size="xl" />
          </div>
        </Col>
        <Col xs="9">
          <div className="mb-3">
            <Form.Label>Profile Image</Form.Label>
            <Form.Control type="file" placeholder="Enter first name" />
          </div>
        </Col>
        <Col xl="12">
          <div className="mb-3">
            <Form.Label>Role</Form.Label>
            <Form.Select aria-label="Default select example">
              <option value="">Role</option>
              <option value="3">Business User </option>
              <option value="4">Business Sub User </option>
              <option value="5">Demo User</option>
            </Form.Select>
          </div>
        </Col>
        <Col xl="12">
          <div className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" placeholder="Enter first name" />
          </div>
        </Col>
        <Col xl="12">
          <div className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" placeholder="Enter last name" />
          </div>
        </Col>
        <Col xl="12">
          <div className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" placeholder="Enter email address" />
          </div>
        </Col>
        <Col xl="12">
          <div className="mb-3">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control type="text" placeholder="Enter contact number" />
          </div>
        </Col>

        <Col xs="3">
          <div className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Check type="switch" style={{ fontSize: "20px" }} label="" />
          </div>
        </Col>
        <Col xs="4">
          <div className="mb-3">
            <Form.Label>Email Verify</Form.Label>
            <Form.Check type="switch" style={{ fontSize: "20px" }} label="" />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default UserForm;
