import React, { useEffect, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { IndustryData } from "../../staticData";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

function FirmographicsSearch(props) {
  const [value, setValue] = useState(50);
  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const Industries = IndustryData;

  const sideSec = {
    height: "450px",
    overflowY: "auto",
  };

  const selectOptions = [];

  return (
    <React.Fragment>
      <Row className="mb-3">
        <label>
          <b>Firmographics</b>
        </label>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Location</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <div className="mb-3">
                <Form.Check type="radio" label="USA" />
                <Form.Check type="radio" label="Non-USA" />
                <Form.Check type="radio" label="Global" />
              </div>
              <div className="mb-3">
                <p>Office Type:</p>
                <Form.Check type="radio" label="HQ" />
                <Form.Check type="radio" label="Branch" />
              </div>
              <p>
                <Link>State</Link> | <Link>Zip Code</Link> |{" "}
                <Link>Metro Area</Link>
              </p>
              <h6>Enter one or more state names or abbreviations.</h6>
              {/* <Form.Control type="text" id="searchbox" placeholder="States" /> */}
              <CreatableSelect
                placeholder="States"
                isMulti
                options={selectOptions}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Keywords</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Control type="text" id="searchbox" placeholder="Keywords" />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Industry</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1" style={sideSec}>
              <p>
                <Link>Industry Names</Link> <br /> <Link>NAICS or SIC</Link>
              </p>
              <p>
                <Link>ANY OF</Link> | <Link>NONE OF</Link>
              </p>
              <Form.Control type="text" id="searchbox" placeholder="Keywords" />
              <br />
              <Form.Check type="checkbox" label="Select All" />
              {Industries?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Revenue</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>Filter results by setting a minimum and/or maximum revenue.</p>
              <Form.Control type="text" id="searchbox" placeholder="No Min" />
              <br />
              <Form.Control type="text" id="searchbox" placeholder="No Max" />
              <br />
              <MultiRangeSlider
                min={0}
                max={1000}
                step={5}
                minValue={minValue}
                maxValue={maxValue}
                onInput={(e) => {
                  handleInput(e);
                }}
                stepOnly={false}
                ruler={false}
                style={{ boxShadow: "none", border: "none" }}
                barInnerColor="#506fd9"
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Employees / Size</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>Filter results by setting a minimum and/or maximum number of employees.</p>
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="No Minimum"
              />
              <br />
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="no Maximum"
              />
              <br />
              <MultiRangeSlider
                min={0}
                max={1000}
                step={5}
                minValue={minValue}
                maxValue={maxValue}
                onInput={(e) => {
                  handleInput(e);
                }}
                stepOnly={false}
                ruler={false}
                style={{ boxShadow: "none", border: "none" }}
                barInnerColor="#506fd9"
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </React.Fragment>
  );
}

export default FirmographicsSearch;
