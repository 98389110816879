import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import SaleTabbing from "../uplead-tabbing/SaleTabbing";
import PerfectScrollbar from "react-perfect-scrollbar";
import ShareUser from "../share-users/ShareUser";

function SalesHarmonyVisitor(props) {
  const [share, setShare] = useState(false);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const handleShowShare = () => setShare(true);
  const handleShareClose = () => setShare(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <SaleTabbing />
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Uplead Visitor
                </li>
              </ol>

              <p className="main-title mb-0">
                {/* Data last generated on October 22, 2022 */}
              </p>
            </div>

            <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
              <div className="revenue-item" key="">
                <div className="revenue-item-body">
                  <span>Visitor Intelligence Credits</span>
                  <span className="ms-4">34 / 100,000</span>
                  <span className="text-success">20%</span>
                </div>
                <ProgressBar now="20" />
              </div>
            </div>
          </div>

          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div></div>

            <div className="d-flex gap-2 mt-3 mt-md-0">
              <div
                className="btn-toolbar"
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                {/* <ButtonGroup aria-label="Third group" className="me-2">
                  <Button variant="primary">View Companies</Button>
                </ButtonGroup>

                <ButtonGroup className="me-2" aria-label="Second group">
                  <Button variant="primary">Yesterday</Button>
                </ButtonGroup> */}
              </div>
            </div>
          </div>

          <Col xl="12">
            <Card.Body>
              <Row>
                <Col md="3" sm="6" className="mb-3">
                  <Card className="card-one">
                    <Card.Body className="p-3">
                      <div className="d-flex d-sm-block d-xl-flex align-items-center">
                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                          <label className="fs-sm fw-medium mb-1 card-title">
                            1 (-98%)
                          </label>
                          <h4 className="card-value mb-1">
                            <i className="ri-home-4-line"></i> Companies
                          </h4>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md="3" sm="6" className="mb-3">
                  <Card className="card-one">
                    <Card.Body className="p-3">
                      <div className="d-flex d-sm-block d-xl-flex align-items-center">
                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                          <label className="fs-sm fw-medium mb-1 card-title">
                            1 (-98%)
                          </label>
                          <h4 className="card-value mb-1">
                            <i className="ri-home-4-line"></i> Users
                          </h4>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md="3" sm="6" className="mb-3">
                  <Card className="card-one">
                    <Card.Body className="p-3">
                      <div className="d-flex d-sm-block d-xl-flex align-items-center">
                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                          <label className="fs-sm fw-medium mb-1 card-title">
                            1 (-98%)
                          </label>
                          <h4 className="card-value mb-1">
                            <i className="ri-home-4-line"></i> Sessions
                          </h4>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md="3" sm="6" className="mb-3">
                  <Card className="card-one">
                    <Card.Body className="p-3">
                      <div className="d-flex d-sm-block d-xl-flex align-items-center">
                        <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                          <label className="fs-sm fw-medium mb-1 card-title">
                            1 (-98%)
                          </label>
                          <h4 className="card-value mb-1">
                            <i className="ri-home-4-line"></i> Views
                          </h4>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Col>

          <Col xl="2">
            <PerfectScrollbar className="contact-sidebar11"></PerfectScrollbar>
            <div>
              <Row className="mb-3">
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Label>
                      <b>Companies</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Companies"
                    />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Label>
                      <b>Users</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Users"
                    />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Label>
                      <b>Sessions</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Sessions"
                    />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Label>
                      <b>Views</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Views"
                    />
                  </div>
                </Col>
                <hr />
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Check type="checkbox" label="Shared With Me" />
                  </div>
                </Col>

                <Col xl="12">
                  <Button variant="primary" className="me-2 w-45">
                    Filter
                  </Button>
                  <Button variant="danger" className="w-45">
                    Reset
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl="10">
            <div className="uplead-bounce-tble">
              <Table className="table-music mb-0 table">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Company</th>
                    <th scope="col">Users</th>
                    <th scope="col">Sessions</th>
                    <th scope="col">Views</th>
                    <th scope="col">Verified</th>
                    <th scope="col">Machine</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">Last Updated</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>4.</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>yaltir.com.tr</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>Aug 20, 2023</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/analytics"
                              >
                                <i className="ri-information-line"></i> View
                                Details
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-star-line"></i> Mark as
                                Important
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="share">
                                <i className="ri-share-forward-line"></i> Share
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="download">
                                <i className="ri-download-2-line"></i> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="copy">
                                <i className="ri-file-copy-line"></i> Copy to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="move">
                                <i className="ri-folders-line"></i> Move to
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="rename">
                                <i className="ri-edit-2-line"></i> Rename
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="delete">
                                <i className="ri-delete-bin-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>
        </Row>
        <Footer />
      </div>

      <Modal
        show={share}
        onHide={handleShareClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Find Connections</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareUser />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SalesHarmonyVisitor;
