import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {
  Button,
  Card,
  Col,
  ProgressBar,
  Row,
  Nav,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Doughnut, Line, PolarArea, Radar } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

import prod1 from "../../assets/img/prod1.jpg";
import prod2 from "../../assets/img/prod2.jpg";
import prod3 from "../../assets/img/prod3.jpg";
import prod4 from "../../assets/img/prod4.jpg";

function AdminDashboard() {
  const states = {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
  };

  const seriesFour = [
    {
      data: [
        [0, 1000],
        [1, 600],
        [2, 500],
        [3, 400],
        [4, 600],
        [5, 500],
        [6, 800],
        [7, 1000],
        [8, 900],
        [9, 1100],
        [10, 1500],
        [11, 1700],
        [12, 1400],
        [13, 1300],
        [14, 1500],
        [15, 1300],
        [16, 1200],
        [17, 1000],
        [18, 1100],
        [19, 800],
        [20, 500],
        [21, 300],
        [22, 500],
        [23, 600],
        [24, 500],
        [25, 600],
        [26, 800],
        [27, 1000],
        [28, 900],
        [29, 800],
        [30, 500],
      ],
    },
    {
      data: [
        [0, 30],
        [1, 30],
        [2, 30],
        [3, 30],
        [4, 30],
        [5, 30],
        [6, 30],
        [7, 30],
        [8, 30],
        [9, 30],
        [10, 30],
        [11, 30],
        [12, 30],
        [13, 30],
        [14, 30],
        [15, 30],
        [16, 30],
        [17, 30],
        [18, 30],
        [19, 30],
        [20, 30],
        [21, 30],
        [22, 30],
        [23, 30],
        [24, 30],
        [25, 30],
        [26, 30],
        [27, 30],
        [28, 30],
        [29, 30],
        [30, 30],
      ],
    },
    {
      data: [
        [0, 800],
        [1, 600],
        [2, 500],
        [3, 400],
        [4, 600],
        [5, 500],
        [6, 800],
        [7, 1000],
        [8, 900],
        [9, 1100],
        [10, 1500],
        [11, 1700],
        [12, 1400],
        [13, 1300],
        [14, 1500],
        [15, 1300],
        [16, 1200],
        [17, 1000],
        [18, 1100],
        [19, 800],
        [20, 500],
        [21, 300],
        [22, 500],
        [23, 600],
        [24, 500],
        [25, 600],
        [26, 800],
        [27, 1000],
        [28, 900],
        [29, 800],
        [30, 500],
      ],
    },
  ];

  //Current Ticket Status
  const dataBar1 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
        backgroundColor: "#506fd9",
        barPercentage: 0.5,
        label: "Basic",
      },
      {
        data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
        backgroundColor: "#85b6ff",
        barPercentage: 0.5,
        label: "Pro",
      },
      {
        data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
        backgroundColor: "#e2e5ec",
        barPercentage: 0.5,
        label: "Enterprise",
      },
    ],
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: "#a1aab3",
          font: {
            size: 10,
          },
        },
        grid: {
          borderColor: "#e2e5ec",
          borderWidth: 1.5,
          color: "rgba(65,80,95,.08)",
        },
      },
      x: {
        ticks: {
          color: "#313c47",
        },
        grid: {
          color: "rgba(65,80,95,.08)",
        },
      },
    },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // Events By Category
  const dataPie = {
    labels: ["Basic", "Pro", "Enterprise"],
    datasets: [
      {
        data: [50, 25, 25],
        backgroundColor: ["#506fd9", "#85b6ff", "#50586d", "#b9bdc9"],
      },
    ],
  };

  const optionPie = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard Management
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome, Super_Name</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Button variant="white" className="btn-icon">
                <i className="ri-share-line fs-18 lh-1"></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Button variant="white" className="btn-icon">
                <i className="ri-printer-line fs-18 lh-1"></i>
              </Button>
            </OverlayTrigger>

            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>

        <Row className="g-3 justify-content-center">
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">4,608</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Total Registered Companies
                    </label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">
                      No of total companies registered
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">4,868</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Total Registered Accounts
                    </label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">
                      No of total Account Types
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">8,785</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Total Revenue Generated
                    </label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">
                      Overall total Revenue
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="12" xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">User Statistics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="chartjs-one">
                  <Bar data={dataBar1} options={optionBar1} />
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recent Searches</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-shopping-cart-line",
                      label: "Search 1",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "info",
                      icon: "ri-coins-line",
                      label: "Search 2",
                      date: "Oct 19, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "primary",
                      icon: "ri-truck-line",
                      label: "Search 3",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "pink",
                      icon: "ri-truck-line",
                      label: "Search 4",
                      date: "Oct 18, 2023, 12:40pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "secondary",
                      icon: "ri-secure-payment-line",
                      label: "Search 5",
                      date: "Oct 15, 2023, 08:09am",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link
                  to="/dashboard/uplead-saved-search"
                  className="fs-sm"
                >
                  View All
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          

          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">User Account Share</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <div className="chart-donut-two mb-4">
                  <Doughnut data={dataPie} options={optionPie} />
                </div>

                <Row className="g-4 mb-2">
                  {[
                    {
                      name: "Companies",
                      value: "9000",
                      percent: "100%",
                      progress: 60,
                    },
                    {
                      name: "Basic",
                      value: "4500",
                      percent: "50%",
                      progress: 50,
                      variant: "ui-02",
                    },
                    {
                      name: "Pro",
                      value: "2250",
                      percent: "25%",
                      progress: 25,
                      variant: "gray-700",
                    },
                    {
                      name: "Enterprise",
                      value: "2250",
                      percent: "25%",
                      progress: 25,
                      variant: "gray-500",
                    },
                  ].map((item, index) => (
                    <Col xs="6" key={index}>
                      <label className="fs-sm fw-medium mb-1">
                        {item.name}
                      </label>
                      <h3 className="card-value mb-2">
                        {item.value} <small>{item.percent}</small>
                      </h3>
                      <ProgressBar
                        now={item.progress}
                        className="ht-5 mb-0"
                        variant={item.variant}
                      />
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Transaction History</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-shopping-cart-line",
                      label: "Purchase from #10322",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "+ $250.00",
                      status: "Completed",
                      color: "success",
                    },
                    {
                      bg: "info",
                      icon: "ri-coins-line",
                      label: "Process refund to #00910",
                      date: "Oct 19, 2023, 3:30pm",
                      value: "- $16.50",
                      status: "Processing",
                      color: "warning",
                    },
                    {
                      bg: "primary",
                      icon: "ri-truck-line",
                      label: "Process delivery to #44333",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "3 Items",
                      status: "For pickup",
                      color: "info",
                    },
                    {
                      bg: "pink",
                      icon: "ri-truck-line",
                      label: "Payment from #023328",
                      date: "Oct 18, 2023, 12:40pm",
                      value: "+ $129.50",
                      status: "Completed",
                      color: "success",
                    },
                    {
                      bg: "secondary",
                      icon: "ri-secure-payment-line",
                      label: "Payment failed #087651",
                      date: "Oct 15, 2023, 08:09am",
                      value: "$150.20",
                      status: "Declined",
                      color: "danger",
                    },
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link to="/admin/payment-lists" className="fs-sm">
                  View All
                </Link>
              </Card.Footer>
            </Card>
          </Col>

          <Col xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recent Registered Companies</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                {[
                  {
                    image: prod1,
                    name: "Amazon",
                    text: "Data Science",
                    price: "Demo",
                    available: "Monthly",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod2,
                    name: "Apple",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod3,
                    name: "Google",
                    text: "Data Science",
                    price: "Demo",
                    available: "Monthly",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Oracle",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Replicon",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Accenture",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Salesforce",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                ].map((item, index) => (
                  <div className="product-wrapper" key={index}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <div className="product-thumb">
                              <img src={item.image} alt="" />
                            </div>
                          </td>
                          <td>
                            <h6 className="mb-1">
                              <Link to="">{item.name}</Link>
                            </h6>
                            <span className="fs-sm text-secondary">
                              {item.text}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Subscription
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.price}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Plan
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.available}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Total Credits
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.sold}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Credits Used
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.sold}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Credits Left
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.sales}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Revenue Shares By Industries</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                {[
                  {
                    name: "Agriculture, Forestry, Fishing and Hunting",
                    amount: "$12,550.50",
                    color: "success",
                    percent: "55.6%",
                    progress: 50,
                  },
                  {
                    name: "Crop Farming",
                    amount: "$9,805.00",
                    color: "danger",
                    percent: "6.8%",
                    progress: 40,
                  },
                  {
                    name: "Dairy & Animal Farming",
                    amount: "$15,004.90",
                    color: "success",
                    percent: "69.2%",
                    progress: 70,
                  },
                  {
                    name: "Forestry & Logging",
                    amount: "$5,338.00",
                    color: "danger",
                    percent: "28.5%",
                    progress: 25,
                  },
                  {
                    name: "Fishing, Hunting & Trapping",
                    amount: "$4,965.40",
                    color: "danger",
                    percent: "18.2%",
                    progress: 20,
                  },
                  {
                    name: "Support Activities for Agriculture and Forestry",
                    amount: "$4,965.40",
                    color: "danger",
                    percent: "18.2%",
                    progress: 20,
                  },
                  {
                    name: "Agriculture, Forestry, Fishing and Hunting",
                    amount: "$4,965.40",
                    color: "danger",
                    percent: "18.2%",
                    progress: 20,
                  },
                  {
                    name: "Arts, Entertainment, and Recreation",
                    amount: "$4,965.40",
                    color: "danger",
                    percent: "18.2%",
                    progress: 20,
                  },
                  {
                    name: "Performing Arts Companies",
                    amount: "$4,965.40",
                    color: "danger",
                    percent: "18.2%",
                    progress: 20,
                  },
                  {
                    name: "Sports",
                    amount: "$4,965.40",
                    color: "danger",
                    percent: "18.2%",
                    progress: 20,
                  },
                ].map((item, index) => (
                  <div className="revenue-item" key={index}>
                    <div className="revenue-item-body">
                      <span>{item.name}</span>
                      <span>{item.amount}</span>
                      <span className={"text-" + item.color}>
                        {item.percent}
                      </span>
                    </div>
                    <ProgressBar now={item.progress} />
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}

export default AdminDashboard;
