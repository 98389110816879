import React from "react";

import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";

//Search Routes
import SalesHarmonyIntelligenceTemp from "../components/uplead-intelligence/SalesHarmonyIntelligenceTemp";
import SalesHarmonyIntelligence from "../components/uplead-intelligence/SalesHarmonyIntelligence";
import SalesHarmonyRod from "../components/uplead-rod/SalesHarmonyRod";
import SalesHarmonySavedSearch from "../components/uplead-saved-search/SalesHarmonySavedSearch";
import SalesHarmonySavedSearchEdit from "../components/uplead-saved-search/SalesHarmonySavedSearchEdit";
import SalesHarmonyListEdit from "../components/uplead-lists/SalesHarmonyListEdit";

import SalesHarmonyList from "../components/uplead-lists/SalesHarmonyList";
import SalesHarmonyBounce from "../components/uplead-bounce/SalesHarmonyBounce";
import SalesHarmonyVisitor from "../components/uplead-visitor/SalesHarmonyVisitor";
import SalesHarmonySavedSearchContactResult from "../components/uplead-saved-search-contact-result/SalesHarmonySavedSearchContactResult";
import AdminDashboard from "../components/super-admin/AdminDashboard";
import EditProfile from "../components/profile/EditProfile";
import ChangePassword from "../components/profile/ChangePassword";

//Super Admin User Module
import UserList from "../components/super-admin/users/UserList";
import CreateUser from "../components/super-admin/users/CreateUser";
import EditUser from "../components/super-admin/users/EditUser";
import ViewUser from "../components/super-admin/users/ViewUser";

//Super Admin Subscription Module
import SubscriptionList from "../components/super-admin/subscriptions/SubscriptionList";
import AddSubscription from "../components/super-admin/subscriptions/AddSubscription";
import EditSubscription from "../components/super-admin/subscriptions/EditSubscription";
import ViewSubscription from "../components/super-admin/subscriptions/ViewSubscription";

//Super Admin Payment Module
import PaymentList from "../components/super-admin/payments/PaymentList";
import ViewPayment from "../components/super-admin/payments/ViewPayment";

//Super Admin Credit Rule
import CreditRule from "../components/super-admin/credits-rules/CreditRule";

//Super Admin Settings
import AdminSettings from "../components/super-admin/settings/AdminSettings";
import AdminDataEnrichment from "../components/super-admin/data-enrichments/AdminDataEnrichment";
import AdminHumanVerified from "../components/super-admin/human-verified/AdminHumanVerified";
import AdminLogs from "../components/super-admin/logs/AdminLogs";

//Company Routes Start
import CompanyHumanVerified from "../components/company-panel/human-verified/CompanyHumanVerified";
import CompanyDashboard from "../components/company-panel/CompanyDashboard";
import CompanyLogs from "../components/company-panel/logs/CompanyLogs";

import CompanyUserList from "../components/company-panel/company-users-list/CompanyUserList";
import CreateCompanyUser from "../components/company-panel/company-users-list/CreateCompanyUser";
import EditCompanyUser from "../components/company-panel/company-users-list/EditCompanyUser";
import CompanyViewUser from "../components/company-panel/company-users-list/CompanyViewUser";

import CompanyPurchasedHistory from "../components/company-panel/purchased-history/CompanyPurchasedHistory";
import CompanyViewPurchasedHistory from "../components/company-panel/purchased-history/CompanyViewPurchasedHistory";

import CompanyDataEnrichment from "../components/company-panel/data-enrichments/CompanyDataEnrichment";

import CompanySettings from "../components/company-panel/settings/CompanySettings";
import PaymentSuccess from "../components/company-panel/payments/PaymentSuccess";
import PaymentFailed from "../components/company-panel/payments/PaymentFailed";
import BuyCredits from "../components/company-panel/buy-credits/BuyCredits";
//Company Routes End

//Company Sub User Start
import CompanySubUserDashboard from "../components/company-sub-panel/CompanySubUserDashboard";
import CompanySubUserPlanList from "../components/company-sub-panel/company-sub-user-plan-lists/CompanySubUserPlanList";
import CompanySubUserPlanView from "../components/company-sub-panel/company-sub-user-plan-lists/CompanySubUserPlanView";
import CompanySubUserDataEnrichment from "../components/company-sub-panel/data-enrichments/CompanySubUserDataEnrichment";
import CompanySubUserLogs from "../components/company-sub-panel/logs/CompanySubUserLogs";
//Company Sub User end

import Analytics from "../components/analytics/Analytics";
import Support from "../components/support/Support";

//DemoUserDashboard
import DemoUserDashboard from "../components/demo-users-panel/DemoUserDashboard";
import DemoUserLogs from "../components/demo-users-panel/logs/DemoUserLogs";

const protectedRoutes = [
  //DemoUserDashboard
  { path: "/demo/user/dashboard", element: <DemoUserDashboard /> },
  { path: "/demo/user/logs", element: <DemoUserLogs /> },
  { path: "/support", element: <Support /> },
  { path: "/analytics", element: <Analytics /> },
  { path: "/admin/human-verified", element: <AdminHumanVerified /> },
  { path: "/admin/logs", element: <AdminLogs /> },

  //Users Module
  { path: "admin/data/enrichment", element: <AdminDataEnrichment /> },
  { path: "admin/users-lists", element: <UserList /> },
  { path: "admin/add-user", element: <CreateUser /> },
  { path: "admin/edit-user", element: <EditUser /> },
  { path: "admin/view-user", element: <ViewUser /> },
  //Credit Rule
  { path: "admin/credit-rules", element: <CreditRule /> },
  //Subscription Module
  { path: "admin/subscription-lists", element: <SubscriptionList /> },
  { path: "admin/add-subscription", element: <AddSubscription /> },
  { path: "admin/edit-subscription", element: <EditSubscription /> },
  { path: "admin/view-subscription", element: <ViewSubscription /> },

  //Payment List
  { path: "admin/payment-lists", element: <PaymentList /> },
  { path: "admin/view-payment", element: <ViewPayment /> },
  //Search Modules
  { path: "edit-profile", element: <EditProfile /> },
  { path: "change-password", element: <ChangePassword /> },
  { path: "admin/dashboard", element: <AdminDashboard /> },
  { path: "admin/settings", element: <AdminSettings /> },
  {
    path: "dashboard/uplead-intelligence",
    element: <SalesHarmonyIntelligence />,
  },
  {
    path: "dashboard/uplead-intelligence/temp",
    element: <SalesHarmonyIntelligenceTemp />,
  },
  { path: "dashboard/uplead-rod", element: <SalesHarmonyRod /> },
  {
    path: "dashboard/uplead-saved-search",
    element: <SalesHarmonySavedSearch />,
  },
  {
    path: "dashboard/uplead-saved-search/edit",
    element: <SalesHarmonySavedSearchEdit />,
  },
  {
    path: "dashboard/uplead-list/edit",
    element: <SalesHarmonyListEdit />,
  },
  {
    path: "dashboard/uplead-lists",
    element: <SalesHarmonyList />,
  },
  {
    path: "dashboard/uplead-bounce",
    element: <SalesHarmonyBounce />,
  },
  {
    path: "dashboard/uplead-visitor",
    element: <SalesHarmonyVisitor />,
  },
  {
    path: "dashboard/sales-harmony-saved-search-result",
    element: <SalesHarmonySavedSearchContactResult />,
  },
  //CompanyDashboard
  { path: "/company/buy/credits", element: <BuyCredits /> },
  { path: "/company/payment/failed", element: <PaymentFailed /> },
  { path: "/company/payment/success", element: <PaymentSuccess /> },
  { path: "/company/human-verified", element: <CompanyHumanVerified /> },
  { path: "company/data/enrichment", element: <CompanyDataEnrichment /> },
  { path: "company/logs", element: <CompanyLogs /> },
  {
    path: "company/dashboard",
    element: <CompanyDashboard />,
  },
  {
    path: "/company/settings",
    element: <CompanySettings />,
  },
  //Users Module
  { path: "company/company-user-lists", element: <CompanyUserList /> },
  { path: "company/add-company-user", element: <CreateCompanyUser /> },
  { path: "company/edit-company-user", element: <EditCompanyUser /> },
  { path: "company/view-company-user", element: <CompanyViewUser /> },
  //CompanyPurchasedHistory
  { path: "company/purchased-history", element: <CompanyPurchasedHistory /> },
  {
    path: "company/view-purchased-history",
    element: <CompanyViewPurchasedHistory />,
  },
  //End
  //Company Sub User Start
  {
    path: "/company/subuser/logs",
    element: <CompanySubUserLogs />,
  },
  {
    path: "/company/subuser/data/enrichment",
    element: <CompanySubUserDataEnrichment />,
  },
  {
    path: "company/subuser/dashboard",
    element: <CompanySubUserDashboard />,
  },
  {
    path: "company/subuser/plan",
    element: <CompanySubUserPlanList />,
  },
  {
    path: "company/subuser/plan/view",
    element: <CompanySubUserPlanView />,
  },
  //Company Sub User End
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
];

export default protectedRoutes;
