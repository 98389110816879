import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Nav,
  Table,
  ProgressBar,
  Form,
} from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";
import { Bar } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import { dp3 } from "./DashboardData";

function Support() {
  //Current Ticket Status
  const dataBar1 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
        backgroundColor: "#506fd9",
        barPercentage: 0.5,
      },
      {
        data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
        backgroundColor: "#85b6ff",
        barPercentage: 0.5,
      },
      {
        data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
        backgroundColor: "#e2e5ec",
        barPercentage: 0.5,
      },
    ],
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: "#a1aab3",
          font: {
            size: 10,
          },
        },
        grid: {
          borderColor: "#e2e5ec",
          borderWidth: 1.5,
          color: "rgba(65,80,95,.08)",
        },
      },
      x: {
        ticks: {
          color: "#313c47",
        },
        grid: {
          color: "rgba(65,80,95,.08)",
        },
      },
    },
  };

  const dataBar2 = {
    labels: [
      "Modification",
      "Code Request",
      "Feature Request",
      "Bug Fix",
      "Integration",
      "Production",
    ],
    datasets: [
      {
        data: [20, 60, 50, 45, 50, 75],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#33d685",
          "#ffc107",
          "#0dcaf0",
          "#ea4c89",
        ],
        barPercentage: 0.5,
      },
      {
        data: [10, 40, 30, 40, 60, 55],
        backgroundColor: "#e2e5ec",
        barPercentage: 0.5,
      },
    ],
  };

  const optionBar2 = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: "#e2e5ec",
          color: "#f3f5f9",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#465463",
          font: {
            size: 13,
          },
        },
      },
    },
  };

  // Complaints Received
  const seriesOne = [
    {
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true },
    },
    colors: ["#506fd9"],
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: { max: 40 },
    yaxis: {
      min: 0,
      max: 80,
    },
    fill: {
      type: "solid",
      opacity: 0.2,
    },
    tooltip: { enabled: false },
  };

  // Time to Resolved Complain
  const optionDonut1 = {
    chart: { parentHeightOffset: 0 },
    colors: ["#506fd9", "#e5e9f2"],
    dataLabels: { enabled: false },
    legend: { show: false },
  };

  const optionDonut2 = {
    chart: { parentHeightOffset: 0 },
    colors: ["#4c5366", "#e5e9f2"],
    dataLabels: { enabled: false },
    legend: { show: false },
  };

  // Agent Performance Score
  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: {
        top: -20,
        left: 0,
        right: -8,
      },
      yaxis: {
        lines: { show: false },
      },
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: "straight",
      width: 1.5,
    },
    xaxis: {
      overwriteCategories: [
        "",
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
        "",
      ],
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 50,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Support
              </li>
            </ol>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}

export default Support;
