import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import SaleTabbing from "../uplead-tabbing/SaleTabbing";
import PerfectScrollbar from "react-perfect-scrollbar";
import ShareUser from "../share-users/ShareUser";
import "./SalesHarmonyRod.css";

function SalesHarmonyRod() {
  const [share, setShare] = useState(false);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const handleShowShare = () => setShare(true);
  const handleShareClose = () => setShare(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <SaleTabbing />

          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Research on Demand Requests
                </li>
              </ol>
            </div>

            <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
              <div className="revenue-item" key="">
                <div className="revenue-item-body">
                  <span>Account RoD Credits</span>
                  <span className="ms-4">34 / 100000</span>
                  <span className="text-success">20%</span>
                </div>
                <ProgressBar now="20" />
              </div>
            </div>
          </div>

          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h5 className="main-title mb-0">
                {/* Research on Demand Requests (91) */}
              </h5>
            </div>

            <div className="d-flex gap-2 mt-3 mt-md-0">
              <h5 className="main-title mb-0"></h5>
            </div>
          </div>

          <Card className="card">
            <Card.Body>
              <Row>
                <Col xl="5" className="">
                  {/* <Form.Label>
                    <b>Filter by Name</b>
                  </Form.Label> */}
                  <div className="mb-3">
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Filter by Name"
                    />
                  </div>
                  <div className="mt-2 d-flex">
                    <Button variant="primary" className="w-50 me-1">Filter</Button>
                    <Button variant="danger" className="w-50 ms-1">Reset</Button>
                  </div>
                </Col>
                <Col xl="7" className="mt-3 mt-xl-0">
                  <Row>
                    <Col xl="12" className="">
                      <div className="mb-3 row">
                        <div className="d-inline-block col-lg-4 col-md-4 col-sm-4">
                          <Form.Check
                            type="checkbox"
                            label={
                              <span>
                                Requested (66){" "}
                                <OverlayTrigger
                                  overlay={<Tooltip>Requested</Tooltip>}
                                >
                                  <i className="ri-question-fill ms-2" />
                                </OverlayTrigger>
                              </span>
                            }
                          />
                        </div>

                        <div className="d-inline-block col-lg-4 col-md-4 col-sm-4">
                          <Form.Check
                            type="checkbox"
                            label={
                              <span>
                                In Progress (1)
                                <OverlayTrigger
                                  overlay={<Tooltip>In Progress</Tooltip>}
                                >
                                  <i className="ri-question-fill ms-2" />
                                </OverlayTrigger>
                              </span>
                            }
                          />
                        </div>

                        <div className="d-inline-block col-lg-4 col-md-4 col-sm-4">
                          <Form.Check
                            type="checkbox"
                            label={
                              <span>
                                In Review (6)
                                <OverlayTrigger
                                  overlay={<Tooltip>In Review</Tooltip>}
                                >
                                  <i className="ri-question-fill ms-2" />
                                </OverlayTrigger>
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl="12" className="">
                      <div className="mb-3 row">
                        <div className="d-inline-block col-lg-4 col-md-4 col-sm-4">
                          <Form.Check
                            type="checkbox"
                            label={
                              <span>
                                Uploading
                                <OverlayTrigger
                                  overlay={<Tooltip>Uploading (0)</Tooltip>}
                                >
                                  <i className="ri-question-fill ms-2" />
                                </OverlayTrigger>
                              </span>
                            }
                          />
                        </div>

                        <div className="d-inline-block col-lg-4 col-md-4 col-sm-4">
                          <Form.Check
                            type="checkbox"
                            label={
                              <span>
                                Completed (0)
                                <OverlayTrigger
                                  overlay={<Tooltip>Completed</Tooltip>}
                                >
                                  <i className="ri-question-fill ms-2" />
                                </OverlayTrigger>
                              </span>
                            }
                          />
                        </div>

                        <div className="d-inline-block col-lg-4 col-md-4 col-sm-4">
                          <Form.Check
                            type="checkbox"
                            label={
                              <span>
                                Shared With Me
                                <OverlayTrigger
                                  overlay={<Tooltip>Shared With Me</Tooltip>}
                                >
                                  <i className="ri-question-fill ms-2" />
                                </OverlayTrigger>
                              </span>
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Col xl="12">
            {/* <Card className="card">
              <Card.Body>
                
              </Card.Body>
            </Card> */}

            <Table className="table-music mb-0 table" responsive>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Requested</th>
                  <th scope="col">ETA</th>
                  <th scope="col">Last Updated</th>
                  <th scope="col">
                    <span className="text-center">Status</span>
                  </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <span className="card-value d-flex align-items-baseline mb-0">
                      [327455] [QA] ROD Request - 3 Contacts
                    </span>
                    <h5 className="card-label fs-sm fw-medium mb-1">
                      No Contacts
                    </h5>
                  </td>
                  <td>May 25, 2022</td>
                  <td>May 25, 9:30 PM</td>
                  <td>4 Hours</td>
                  <td style={{ width: "500px" }}>
                    <div className="progress-track">
                      <ul className="progressbar">
                        <li className="step0 active step1" id="">
                          Requested
                        </li>
                        <li className="step0 active text-center step2" id="">
                          In Progress
                        </li>
                        <li className="step0 active text-center step3" id="">
                          In Review
                        </li>
                        <li className="step0 text-center step4" id="">
                          Uploading
                        </li>
                        <li className="step0 step5" id="">
                          Completed
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td style={{ width: "70px" }}>
                    <Row>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                          <Link
                            to=""
                            style={{ color: "#6e7985" }}
                            onClick={handleShowShare}
                          >
                            <i className="ri-user-add-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                          <Link to="" style={{ color: "#6e7985" }}>
                            <i className="ri-delete-bin-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="1">
                        <Dropdown align="end" className="dropdown-file">
                          <Dropdown.Toggle className="" as={CustomToggle}>
                            <i className="ri-more-2-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-eye-fill"></i> View
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-pencil-fill"></i> Edit
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-delete-bin-6-line"></i> Delete
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-folder-shared-fill"></i> Share To
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </td>
                  <td style={{ width: "165px" }}>
                    <Nav className="nav-icon ms-auto">
                      <a
                        href="#"
                        title="View"
                        role="button"
                        class=""
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-eye-fill"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-pencil-fill"></i>
                      </a>

                      <a
                        href="#"
                        title="Delete"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-delete-bin-6-line"></i>
                      </a>

                      <a
                        href="#"
                        title="Share"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-folder-shared-fill"></i>
                      </a>
                    </Nav>
                  </td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>
                    <span className="card-value d-flex align-items-baseline mb-0">
                      [327455] [QA] ROD Request - 3 Contacts
                    </span>
                    <h5 className="card-label fs-sm fw-medium mb-1">
                      No Contacts
                    </h5>
                  </td>
                  <td>May 25, 2022</td>
                  <td>May 25, 9:30 PM</td>
                  <td>4 Hours</td>
                  <td style={{ width: "500px" }}>
                    <div className="progress-track">
                      <ul className="progressbar">
                        <li className="step0 active step1" id="">
                          Requested
                        </li>
                        <li className="step0 active text-center step2" id="">
                          In Progress
                        </li>
                        <li className="step0 active text-center step3" id="">
                          In Review
                        </li>
                        <li className="step0 text-center step4" id="">
                          Uploading
                        </li>
                        <li className="step0 step5" id="">
                          Completed
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td style={{ width: "70px" }}>
                    <Row>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                          <Link
                            to=""
                            style={{ color: "#6e7985" }}
                            onClick={handleShowShare}
                          >
                            <i className="ri-user-add-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                          <Link to="" style={{ color: "#6e7985" }}>
                            <i className="ri-delete-bin-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="1">
                        <Dropdown align="end" className="dropdown-file">
                          <Dropdown.Toggle className="" as={CustomToggle}>
                            <i className="ri-more-2-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-eye-fill"></i> View
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-pencil-fill"></i> Edit
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-delete-bin-6-line"></i> Delete
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-folder-shared-fill"></i> Share To
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </td>
                  <td style={{ width: "165px" }}>
                    <Nav className="nav-icon ms-auto">
                      <a
                        href="#"
                        title="View"
                        role="button"
                        class=""
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-eye-fill"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-pencil-fill"></i>
                      </a>

                      <a
                        href="#"
                        title="Delete"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-delete-bin-6-line"></i>
                      </a>

                      <a
                        href="#"
                        title="Share"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-folder-shared-fill"></i>
                      </a>
                    </Nav>
                  </td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>
                    <span className="card-value d-flex align-items-baseline mb-0">
                      [327455] [QA] ROD Request - 3 Contacts
                    </span>
                    <h5 className="card-label fs-sm fw-medium mb-1">
                      No Contacts
                    </h5>
                  </td>
                  <td>May 25, 2022</td>
                  <td>May 25, 9:30 PM</td>
                  <td>4 Hours</td>
                  <td style={{ width: "500px" }}>
                    <div className="progress-track">
                      <ul className="progressbar">
                        <li className="step0 active step1" id="">
                          Requested
                        </li>
                        <li className="step0 active text-center step2" id="">
                          In Progress
                        </li>
                        <li className="step0 active text-center step3" id="">
                          In Review
                        </li>
                        <li className="step0 text-center step4" id="">
                          Uploading
                        </li>
                        <li className="step0 step5" id="">
                          Completed
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td style={{ width: "70px" }}>
                    <Row>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                          <Link
                            to=""
                            style={{ color: "#6e7985" }}
                            onClick={handleShowShare}
                          >
                            <i className="ri-user-add-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                          <Link to="" style={{ color: "#6e7985" }}>
                            <i className="ri-delete-bin-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="1">
                        <Dropdown align="end" className="dropdown-file">
                          <Dropdown.Toggle className="" as={CustomToggle}>
                            <i className="ri-more-2-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-eye-fill"></i> View
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-pencil-fill"></i> Edit
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-delete-bin-6-line"></i> Delete
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-folder-shared-fill"></i> Share To
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </td>
                  <td style={{ width: "165px" }}>
                    <Nav className="nav-icon ms-auto">
                      <a
                        href="#"
                        title="View"
                        role="button"
                        class=""
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-eye-fill"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-pencil-fill"></i>
                      </a>

                      <a
                        href="#"
                        title="Delete"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-delete-bin-6-line"></i>
                      </a>

                      <a
                        href="#"
                        title="Share"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-folder-shared-fill"></i>
                      </a>
                    </Nav>
                  </td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>
                    <span className="card-value d-flex align-items-baseline mb-0">
                      [327455] [QA] ROD Request - 3 Contacts
                    </span>
                    <h5 className="card-label fs-sm fw-medium mb-1">
                      No Contacts
                    </h5>
                  </td>
                  <td>May 25, 2022</td>
                  <td>May 25, 9:30 PM</td>
                  <td>4 Hours</td>
                  <td style={{ width: "500px" }}>
                    <div className="progress-track">
                      <ul className="progressbar">
                        <li className="step0 active step1" id="">
                          Requested
                        </li>
                        <li className="step0 active text-center step2" id="">
                          In Progress
                        </li>
                        <li className="step0 active text-center step3" id="">
                          In Review
                        </li>
                        <li className="step0 text-center step4" id="">
                          Uploading
                        </li>
                        <li className="step0 step5" id="">
                          Completed
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td style={{ width: "70px" }}>
                    <Row>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                          <Link
                            to=""
                            style={{ color: "#6e7985" }}
                            onClick={handleShowShare}
                          >
                            <i className="ri-user-add-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                          <Link to="" style={{ color: "#6e7985" }}>
                            <i className="ri-delete-bin-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="1">
                        <Dropdown align="end" className="dropdown-file">
                          <Dropdown.Toggle className="" as={CustomToggle}>
                            <i className="ri-more-2-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-eye-fill"></i> View
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-pencil-fill"></i> Edit
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-delete-bin-6-line"></i> Delete
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-folder-shared-fill"></i> Share To
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </td>
                  <td style={{ width: "165px" }}>
                    <Nav className="nav-icon ms-auto">
                      <a
                        href="#"
                        title="View"
                        role="button"
                        class=""
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-eye-fill"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-pencil-fill"></i>
                      </a>

                      <a
                        href="#"
                        title="Delete"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-delete-bin-6-line"></i>
                      </a>

                      <a
                        href="#"
                        title="Share"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-folder-shared-fill"></i>
                      </a>
                    </Nav>
                  </td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>
                    <span className="card-value d-flex align-items-baseline mb-0">
                      [327455] [QA] ROD Request - 3 Contacts
                    </span>
                    <h5 className="card-label fs-sm fw-medium mb-1">
                      No Contacts
                    </h5>
                  </td>
                  <td>May 25, 2022</td>
                  <td>May 25, 9:30 PM</td>
                  <td>4 Hours</td>
                  <td style={{ width: "500px" }}>
                    <div className="progress-track">
                      <ul className="progressbar">
                        <li className="step0 active step1" id="">
                          Requested
                        </li>
                        <li className="step0 active text-center step2" id="">
                          In Progress
                        </li>
                        <li className="step0 active text-center step3" id="">
                          In Review
                        </li>
                        <li className="step0 text-center step4" id="">
                          Uploading
                        </li>
                        <li className="step0 step5" id="">
                          Completed
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td style={{ width: "70px" }}>
                    <Row>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                          <Link
                            to=""
                            style={{ color: "#6e7985" }}
                            onClick={handleShowShare}
                          >
                            <i className="ri-user-add-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="2" className="mt-1">
                        <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                          <Link to="" style={{ color: "#6e7985" }}>
                            <i className="ri-delete-bin-line"></i>
                          </Link>
                        </OverlayTrigger>
                      </Col>
                      <Col xs="1">
                        <Dropdown align="end" className="dropdown-file">
                          <Dropdown.Toggle className="" as={CustomToggle}>
                            <i className="ri-more-2-fill"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-eye-fill"></i> View
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="important"
                              as={Link}
                              to=""
                            >
                              <i className="ri-pencil-fill"></i> Edit
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-delete-bin-6-line"></i> Delete
                            </Dropdown.Item>
                            <Dropdown.Item href="#" className="important">
                              <i className="ri-folder-shared-fill"></i> Share To
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </td>
                  <td style={{ width: "165px" }}>
                    <Nav className="nav-icon ms-auto">
                      <a
                        href="#"
                        title="View"
                        role="button"
                        class=""
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-eye-fill"></i>
                      </a>
                      <a
                        href="#"
                        title="Edit"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i class="ri-pencil-fill"></i>
                      </a>

                      <a
                        href="#"
                        title="Delete"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-delete-bin-6-line"></i>
                      </a>

                      <a
                        href="#"
                        title="Share"
                        role="button"
                        class="ms-2"
                        tabindex="0"
                        style={{ color: "rgb(110, 121, 133)" }}
                      >
                        <i className="ri-folder-shared-fill"></i>
                      </a>
                    </Nav>
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>
        </Row>
        <Footer />
      </div>

      <Modal
        show={share}
        onHide={handleShareClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Find Connections</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareUser />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SalesHarmonyRod;
