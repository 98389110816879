import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import {
  Button,
  Card,
  Col,
  ProgressBar,
  Row,
  Nav,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Doughnut, Line, PolarArea, Radar } from "react-chartjs-2";

import prod1 from "../../assets/img/prod1.jpg";
import prod2 from "../../assets/img/prod2.jpg";
import prod3 from "../../assets/img/prod3.jpg";
import prod4 from "../../assets/img/prod4.jpg";
import prod5 from "../../assets/img/prod5.jpg";

function DemoUserDashboard(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard Management
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome, Demo_User</h4>
          </div>

          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Button variant="white" className="btn-icon">
                <i className="ri-share-line fs-18 lh-1"></i>
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Button variant="white" className="btn-icon">
                <i className="ri-printer-line fs-18 lh-1"></i>
              </Button>
            </OverlayTrigger>
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>

        <Row className="g-3 justify-content-center">
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">4,608</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Total Leads
                    </label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">
                      No of total leads contacts
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">4,868</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Total Conversions
                    </label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">
                      No of total leads converted
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <h3 className="card-value mb-1">8,785</h3>
                    <label className="card-title fw-medium text-dark mb-1">
                      Total Revenue
                    </label>
                    <span className="d-block text-muted fs-11 ff-secondary lh-4">
                      Total revenue generated
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recent Registered Companies</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                {[
                  {
                    image: prod1,
                    name: "Amazon",
                    text: "Data Science",
                    price: "Demo",
                    available: "Monthly",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod2,
                    name: "Apple",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod3,
                    name: "Google",
                    text: "Data Science",
                    price: "Demo",
                    available: "Monthly",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Oracle",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Replicon",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Accenture",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                  {
                    image: prod4,
                    name: "Salesforce",
                    text: "Data Science",
                    price: "Demo",
                    available: "Annual",
                    total: "140",
                    sold: "40",
                    sales: "100",
                  },
                ].map((item, index) => (
                  <div className="product-wrapper" key={index}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <div className="product-thumb">
                              <img src={item.image} alt="" />
                            </div>
                          </td>
                          <td>
                            <h6 className="mb-1">
                              <Link to="">{item.name}</Link>
                            </h6>
                            <span className="fs-sm text-secondary">
                              {item.text}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Subscription
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.price}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Plan
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.available}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Total Credits
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.sold}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Credits Used
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.sold}
                            </span>
                          </td>
                          <td>
                            <label className="d-block text-secondary fs-sm mb-1">
                              Credits Left
                            </label>
                            <span className="d-block fw-medium text-dark ff-numerals">
                              {item.sales}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Transaction History</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-shopping-cart-line",
                      label: "Purchase from #10322",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "+ $250.00",
                      status: "Completed",
                      color: "success",
                    },
                    {
                      bg: "info",
                      icon: "ri-coins-line",
                      label: "Process refund to #00910",
                      date: "Oct 19, 2023, 3:30pm",
                      value: "- $16.50",
                      status: "Processing",
                      color: "warning",
                    },
                    {
                      bg: "primary",
                      icon: "ri-truck-line",
                      label: "Process delivery to #44333",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "3 Items",
                      status: "For pickup",
                      color: "info",
                    },
                    {
                      bg: "pink",
                      icon: "ri-truck-line",
                      label: "Payment from #023328",
                      date: "Oct 18, 2023, 12:40pm",
                      value: "+ $129.50",
                      status: "Completed",
                      color: "success",
                    },
                    {
                      bg: "secondary",
                      icon: "ri-secure-payment-line",
                      label: "Payment failed #087651",
                      date: "Oct 15, 2023, 08:09am",
                      value: "$150.20",
                      status: "Declined",
                      color: "danger",
                    },
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link to="/admin/payment-lists" className="fs-sm">
                  View All
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default DemoUserDashboard;
