import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
  adminMenus,
  companyMenu,
  companySubUserMenu,
  demoUserMenu,
  otherUserMenu,
  searchPanelMenu,
} from "../data/Menu";

import logoImg from "../assets/img/logo.svg";

const logoCss = {
  textAlign: "center",
  width: "100%",
};

const logoImgCss = {
  height: "35px",
};



export default class Sidebar extends Component {
  toggleFooterMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };

  render() {
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          <Link to="/" className="sidebar-logo" style={logoCss}>
            <img src={logoImg} alt="logo" style={logoImgCss} />
          </Link>
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={(ref) => (this._scrollBarRef = ref)}
        >
          <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
        </PerfectScrollbar>
      </div>
    );
  }
}

const userRole = localStorage.getItem("user_role");

class SidebarMenu extends Component {
  //dashboardMenu
  populateMenu = (m) => {
    const menu = m.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink to={sm.link} className="nav-sub-link" key={key}>
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={this.toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    this.props.onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    this.props.onUpdateSize();
  };

  render() {
    return (
      <React.Fragment>
        {/* companyMenu */}
        {userRole && userRole == "company" && (
          <div className="nav-group show">
            <div className="nav-label" onClick={this.toggleMenu}>
              Company Menu
            </div>
            {this.populateMenu(companyMenu)}
          </div>
        )}
        {/* adminMenus */}
        {userRole && userRole == "super-admin" && (
          <div className="nav-group show">
            <div className="nav-label" onClick={this.toggleMenu}>
              Admin Menu
            </div>
            {this.populateMenu(adminMenus)}
          </div>
        )}
        {/* companySubUserMenu */}
        {userRole && userRole == "company-user" && (
          <div className="nav-group show">
            <div className="nav-label" onClick={this.toggleMenu}>
              Company Sub User Menu
            </div>
            {this.populateMenu(companySubUserMenu)}
          </div>
        )}
        {/* demoUserMenu */}
        {userRole && userRole == "demo-user" && (
          <div className="nav-group show">
            <div className="nav-label" onClick={this.toggleMenu}>
              Demo User Menu
            </div>
            {this.populateMenu(demoUserMenu)}
          </div>
        )}

        <div className="nav-group show d-block d-lg-none">
          <div className="nav-label" onClick={this.toggleMenu}>
            Search Panel
          </div>
          {this.populateMenu(searchPanelMenu)}
        </div>

        <div className="nav-group show">
          <div className="nav-label" onClick={this.toggleMenu}>
            Other Menu
          </div>
          {this.populateMenu(otherUserMenu)}
        </div>
        
      </React.Fragment>
    );
  }
}

window.addEventListener("click", function (e) {
  // Close sidebar footer menu when clicked outside of it
  let tar = e.target;
  let sidebar = document.querySelector(".sidebar");
  if (!tar.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("sidebar-skin");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});
