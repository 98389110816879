import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  Navbar,
  NavLink,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SaleHeader.css";

function SaleHeader(props) {
  return (
    <React.Fragment>
      {/* <Nav className="ms-auto justify-content-end">
        <Nav.Item>
          <Nav.Link>
            <i className="ri-brush-3-line"></i> 146,602 / 150,000
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <i className="ri-paint-brush-line"></i> 0 / 12,345
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/dashboard/uplead-rod">
            Research on Demand
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/support">
            Support
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link to="">Resources</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link to="">Training</Nav.Link>
        </Nav.Item>
      </Nav> */}

      {/* <Nav className="ms-auto justify-content-end">
        <Nav.Item>
          <Nav.Link>
            <Link
              className="nav-link"
              to="/dashboard/uplead-intelligence"
            >
              <i className="ri-home-4-line"></i> Sales Intelligence
            </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link className="nav-link" to="/dashboard/uplead-rod">
              <i className="ri-sticky-note-line"></i> Sales ROD
            </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link className="nav-link" to="/dashboard/uplead-bounce">
              <i className="ri-indent-increase"></i> Bounce Intelligence
            </Link>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link>
            <Link className="nav-link" to="/dashboard/uplead-visitor">
              <i className="ri-coupon-line"></i> Visitor Intelligence
            </Link>
          </Nav.Link>
        </Nav.Item>
      </Nav> */}
      <div className="d-none d-lg-block ms-auto">
        <Navbar className="ms-auto" expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="header_icon">
            <Nav className="ms-auto">
              <Nav.Item>
                
                <Link
                  className="nav-link"
                  to="/dashboard/uplead-intelligence"
                >
                  <i className="ri-money-dollar-circle-line"></i> Sales
                  Intelligence
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/dashboard/uplead-rod">
                  <i className="ri-zoom-in-line"></i> Sales ROD
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/dashboard/uplead-bounce">
                  <i className="ri-funds-line"></i> Bounce Intelligence
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="nav-link" to="/dashboard/uplead-visitor">
                  <i className="ri-eye-2-line"></i> Visitor Intelligence
                </Link>
              </Nav.Item>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </React.Fragment>
  );
}

export default SaleHeader;
