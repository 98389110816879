import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Grid } from "gridjs-react";
import Select from "react-select";

function CompanyViewPurchasedHistory(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/company/purchased-history">
                    Purchased History List
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  View Purchased History
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Link to="/company/purchased-history" className="btn btn-primary">
                Back
              </Link>
            </div>
          </div>

          <Col xl="2"></Col>
          <Col xl="8">
            <Card className="card-post mt-4">
              <Card.Body>
                <Table className="mb-0" striped bordered hover responsive>
                  <tbody>
                    <tr>
                      <th>Transaction ID: </th>
                      <td>TAX-HTSFHJH</td>
                    </tr>
                    <tr>
                      <th>Plan Type: </th>
                      <td>Monthly</td>
                    </tr>
                    <tr>
                      <th>Plan Name: </th>
                      <td>esting Plan ABC</td>
                    </tr>
                    <tr>
                      <th>Amount: </th>
                      <td>$10</td>
                    </tr>
                    <tr>
                      <th>Payment At: </th>
                      <td>Dec 15, 2020</td>
                    </tr>
                    <tr>
                      <th>Plan Start Date: </th>
                      <td>Dec 15, 2020</td>
                    </tr>
                    <tr>
                      <th>Plan End Date: </th>
                      <td>Jan 15, 2021</td>
                    </tr>
                    <tr>
                      <th>Payment Status: </th>
                      <td>
                        <Badge bg="success" pill>
                          Success
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th>Card Holder Name</th>
                      <td>Jeams Thomas</td>
                    </tr>
                    <tr>
                      <th>Card Type</th>
                      <td>Visa</td>
                    </tr>
                    <tr>
                      <th>Card No.</th>
                      <td>XXXX XXXX XXXX 4521</td>
                    </tr>
                    <tr>
                      <th>Card Expaire</th>
                      <td>XX23</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="2"></Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanyViewPurchasedHistory;
