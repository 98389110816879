import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import Avatar from "../../Avatar";

import paymentSuccess from "../../../assets/img/payment-success.png";

function PaymentSuccess() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3 justify-content-center">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/payment-lists">Payment Lists</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payment Success
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>

          <Col xl="8">
            <Card className="card">
              <Card.Body>
                <Row className="mt-2">
                  <Col xs="12" className="text-end text-secondary">
                    <div className="text-center">
                      <img src={paymentSuccess} style={{width:"10%"}} alt="" />
                    </div>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Transaction ID
                  </Col>
                  <Col>: #258HGGG</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Plan Name
                  </Col>
                  <Col> : Basic</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Plan Type
                  </Col>
                  <Col> : Monthly</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Amount
                  </Col>
                  <Col> : $500</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Credits
                  </Col>
                  <Col> : 50000</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Purchase Date
                  </Col>
                  <Col> : Dec 15, 2020</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Plan Start Date
                  </Col>
                  <Col> : Dec 15, 2020</Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="6" className="text-end text-secondary">
                    Plan End Date
                  </Col>
                  <Col> : Jan 15, 2021</Col>
                </Row>
                <Row className="mt-4">
                  <Col xs="12">
                    <div className="text-center">
                      <Link
                        to="/company/purchased-history"
                        className="btn btn-primary"
                      >
                        <i className="ri-list-check-2"></i> Payment History
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default PaymentSuccess;
