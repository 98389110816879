import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import SaleTabbing from "../uplead-tabbing/SaleTabbing";
import PerfectScrollbar from "react-perfect-scrollbar";
import ShareUser from "../share-users/ShareUser";

function SalesHarmonyList() {
  const [show3, setShow3] = useState(false);

  const [share, setShare] = useState(false);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const handleShow3 = () => setShow3(true);
  const handleClose3 = () => setShow3(false);

  const handleShowShare = () => setShare(true);
  const handleShareClose = () => setShare(false);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <SaleTabbing />
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lists
                </li>
              </ol>
              <h5 className="main-title mb-0">{/* Lists (185) */}</h5>
            </div>

            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>

          <Col xl="2">
            <PerfectScrollbar className="contact-sidebar11"></PerfectScrollbar>
            <div>
              <Row className="mb-3">
                <div className="d-grid mb-4">
                  <Button variant="primary" onClick={handleShow3}>
                    <i className="ri-file-add-line"></i> Create List
                  </Button>
                </div>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Label>
                      <b>Filter by Name</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Filter by Name"
                    />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Check type="checkbox" label="Company List (80)" />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Check type="checkbox" label="Contact List (76)" />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Check type="checkbox" label="Suppression List (29)" />
                  </div>
                </Col>
                <hr />
                <Col xl="12">
                  <div className="mb-3">
                    <Form.Check type="checkbox" label="Shared With Me" />
                  </div>
                </Col>
                <Col xl="12">
                  <Button variant="primary" className="me-2 w-45">
                    Filter
                  </Button>
                  <Button variant="danger" className="w-45">
                    Reset
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl="10">
            <div className="uplead-bounce-tble">
              <Table className="table-music mb-0 table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Count</th>
                    <th scope="col">Date</th>
                    <th scope="col">Last Updated</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <Link to="">Test1 Testinson-test4013 </Link>
                      <p>Lorem Ipsum is simply dummy text...</p>
                    </td>
                    <td>Contact List</td>
                    <td>0</td>
                    <td>Feb 14, 2020</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to=""
                              >
                                <i className="ri-eye-fill"></i> View
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/dashboard/uplead-list/edit"
                              >
                                <i className="ri-pencil-fill"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-delete-bin-6-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <Link to="">Test1 Testinson-test11 </Link>
                      <p>Lorem Ipsum is simply dummy text...</p>
                    </td>
                    <td>Contact List</td>
                    <td>2</td>
                    <td>Feb 18, 2020</td>
                    <td>5 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to=""
                              >
                                <i className="ri-eye-fill"></i> View
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/dashboard/uplead-list/edit"
                              >
                                <i className="ri-pencil-fill"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-delete-bin-6-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <Link to="">
                        Test1 Testinson-Test1 Testinson-Yogesh123
                      </Link>
                      <p>Lorem Ipsum is simply dummy text...</p>
                    </td>
                    <td>Company List</td>
                    <td>2</td>
                    <td>March 18, 2020</td>
                    <td>5 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to=""
                              >
                                <i className="ri-eye-fill"></i> View
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/dashboard/uplead-list/edit"
                              >
                                <i className="ri-pencil-fill"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-delete-bin-6-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <Link to="">Test1 Testinson-Test1 Testinson-Test1..</Link>
                      <p>Lorem Ipsum is simply dummy text...</p>
                    </td>
                    <td>Contact List</td>
                    <td>2</td>
                    <td>March 18, 2020</td>
                    <td>5 Hours</td>
                    <td>
                      {/* <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Button variant="warning" className="btn-icon">
                              <i className="ri-eye-fill"></i>
                            </Button>
                          </ButtonGroup>

                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Button variant="primary" className="btn-icon">
                              <i className="ri-pencil-fill"></i>
                            </Button>
                          </ButtonGroup>

                          <ButtonGroup
                            className="me-2"
                            aria-label="Second group"
                          >
                            <Button variant="danger" className="btn-icon">
                              <i className="ri-delete-bin-6-line"></i>
                            </Button>
                          </ButtonGroup>
                        </div> */}
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to=""
                              >
                                <i className="ri-eye-fill"></i> View
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/dashboard/uplead-list/edit"
                              >
                                <i className="ri-pencil-fill"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-delete-bin-6-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>
                      <Link to="">Test1 Testinson..</Link>
                      <p>Lorem Ipsum is simply dummy text...</p>
                    </td>
                    <td>Contact List</td>
                    <td>2</td>
                    <td>March 20, 2020</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to=""
                              >
                                <i className="ri-eye-fill"></i> View
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/dashboard/uplead-list/edit"
                              >
                                <i className="ri-pencil-fill"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-delete-bin-6-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>
                      <Link to="">Test1 Testinson-test4013 </Link>
                      <p>Lorem Ipsum is simply dummy text...</p>
                    </td>
                    <td>Contact List</td>
                    <td>0</td>
                    <td>Feb 14, 2020</td>
                    <td>4 Hours</td>
                    <td>
                      <Row>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
                            <Link
                              to=""
                              style={{ color: "#6e7985" }}
                              onClick={handleShowShare}
                            >
                              <i className="ri-user-add-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2" className="mt-1">
                          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                            <Link to="" style={{ color: "#6e7985" }}>
                              <i className="ri-delete-bin-line"></i>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                        <Col xl="2">
                          <Dropdown align="end" className="dropdown-file">
                            <Dropdown.Toggle as={CustomToggle}>
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to=""
                              >
                                <i className="ri-eye-fill"></i> View
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="details"
                                as={Link}
                                to="/dashboard/uplead-list/edit"
                              >
                                <i className="ri-pencil-fill"></i> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" className="important">
                                <i className="ri-delete-bin-6-line"></i> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>
        </Row>
        <Footer />
      </div>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add To List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Col sm="12">
                <Form.Label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  List Name
                </Form.Label>
                <Form.Control
                  type="taxt"
                  id="inputEmail3"
                  placeholder="Pleasse Enter List Name"
                />
              </Col>
              <Col sm="12" className="mt-2">
                <Form.Label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label"
                >
                  Visibility
                </Form.Label>
                <div>
                  <div className="d-inline-block">
                    <Form.Check type="radio" label="Public" />
                  </div>
                  <div className="d-inline-block ms-3">
                    <Form.Check type="radio" checked label="Private" />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="primary">Add</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={share}
        onHide={handleShareClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Find Connections</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareUser />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SalesHarmonyList;
