import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from "../Avatar";

//import img6 from "../../assets/img/img6.jpg";
import img6 from "../../assets/img/avatar.png";

function ShareUser(props) {
  return (
    <React.Fragment>
      <p className="text-secondary fs-sm mb-4">
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
        officia.
      </p>

      <div className="form-search">
        <i className="ri-search-line"></i>
        <Form.Control type="text" placeholder="Enter name or email" />
      </div>

      <hr className="my-4 opacity-0" />

      <h5 className="section-title mb-4">Share With</h5>
      <ul className="people-group">
        {[
          {
            avatar: img6,
            name: "Allan Rey Palban",
            position: "Senior Business Analyst",
          },
          {
            avatar: img6,
            name: "Adrian Moniño",
            position: "Software Engineer",
          },
          {
            avatar: img6,
            name: "Charlene Plateros",
            position: "Sales Representative",
          },
          {
            avatar: img6,
            name: "Analyn Mercado",
            position: "Executive Assistant",
          },
          {
            avatar: img6,
            name: "Rolando Paloso",
            position: "Senior Architect",
          },
        ].map((people, index) => (
          <li className="people-item" key={index}>
            <Avatar img={people.avatar} />
            <div className="people-body">
              <h6>
                <Link to="">{people.name}</Link>
              </h6>
              <span>{people.position}</span>
            </div>
            <Button variant="secondary" className="ms-auto btn-sm">
              Share
            </Button>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
}

export default ShareUser;
