import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import ReactDatePicker from "react-datepicker";
import { Grid } from "gridjs-react";
import Select from "react-select";
import Avatar from "../../Avatar";

function CompanySubUserPlanList(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [filterBy, setFilterBy] = useState("");
  const [filterType, setFilterType] = useState("");
  //Filter Function
  const filterPayment = (e) => {
    const postdata = {
      keyword: filterBy,
      type: filterType,
    };
    console.log(postdata);
  };

  //Reset
  const resetFilterPayment = (e) => {
    const postdata = {
      keyword: "",
    };
    console.log(postdata);
    setFilterBy("");
    setFilterType("");
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const verification = [
    {
      value: "1",
      label: "Verify",
    },
    { value: "0", label: "Unverify" },
  ];

  const planDuration = [
    {
      value: "1",
      label: "Monthly",
    },
    { value: "2", label: "Yearly" },
  ];

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/subuser/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Plan List
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>

          <Col xl="12">
            <Card className="card">
              <Card.Body>
                <Row>
                  <Col xl="2" className="mb-3">
                    <Form.Control
                      type="text"
                      id="searchbox"
                      placeholder="Filter by Plan Name"
                      onChange={(e) => setFilterBy(e.target.value)}
                      value={filterBy}
                    />
                  </Col>
                  <Col xl="2" className="mb-3">
                    <Select
                      name="user_role"
                      placeholder="Plan Duration"
                      options={planDuration}
                      isSearchable={false}
                      isClearable
                    />
                  </Col>
                  <Col xl="2" className="mb-3">
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={filterPayment}
                    >
                      Filter
                    </Button>
                    <Button variant="danger" onClick={resetFilterPayment}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="12">
            <Table className="table-music mb-0 table" responsive>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Plan Name</th>
                  <th scope="col">Credits</th>
                  <th scope="col">Price</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>
                    Testing Plan ABC
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>Dec 15, 2020</td>
                  <td>Jan 15, 2021</td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/company/subuser/plan/view"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanySubUserPlanList;
