import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import Footer from "../../../layouts/Footer";

function SubscriptionList(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [filterBy, setFilterBy] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  //Filter Function
  const filterPayment = (e) => {
    const postdata = {
      keyword: filterBy,
      type: filterType,
      status: filterStatus,
    };
    console.log(postdata);
  };

  //Reset
  const resetFilterPayment = (e) => {
    const postdata = {
      keyword: "",
      type: "",
      status: "",
    };
    console.log(postdata);
    setFilterBy("");
    setFilterType("");
    setFilterStatus("");
  };

  const userRole = [
    {
      value: "Company",
      label: "Company",
    },
    { value: "Company Sub User", label: "Company Sub User" },
    { value: "Demo User", label: "Demo User" },
  ];

  const companiesList = [
    {
      value: "Google",
      label: "Google",
    },
    { value: "Amazon", label: "Amazon" },
    { value: "Apple", label: "Apple" },
  ];

  const planType = [
    {
      value: "Basic",
      label: "Basic",
    },
    { value: "Pro", label: "Pro" },
    { value: "Enterprise", label: "Enterprise" },
  ];

  const planDuration = [
    {
      value: "1",
      label: "Monthly",
    },
    { value: "2", label: "Yearly" },
  ];

  const status = [
    {
      value: "1",
      label: "Active",
    },
    { value: "0", label: "Inactive" },
  ];

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Subscription List
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              <Link to="/admin/add-subscription" className="btn btn-primary">
                <i className="ri-file-add-line"></i> Add Subscription
              </Link>
            </div>
          </div>

          <Card className="card">
            <Card.Body>
              <Row>
                {/* <Col xl="3" className="mb-3">
                  <Form.Control
                    type="text"
                    id="searchboxdd"
                    placeholder="Filter by Plan Name"
                    onChange={(e) => setFilterBy(e.target.value)}
                    value={filterBy}
                  />
                </Col> */}
                <Col xl="2" className="mb-3">
                  <Select
                    name="user_role"
                    placeholder="Account Type"
                    options={userRole}
                    isSearchable={false}
                    isClearable
                  />
                </Col>
                <Col xl="2" className="mb-3">
                  <Select
                    name="user_role"
                    placeholder="Filter By Company"
                    options={companiesList}
                    isSearchable={false}
                    isClearable
                  />
                </Col>
                <Col xl="2" className="mb-3">
                  <Select
                    name="user_role"
                    placeholder="Plan Type"
                    options={planType}
                    isSearchable={false}
                    isClearable
                  />
                </Col>
                <Col xl="2" className="mb-3">
                  <Select
                    name="user_role"
                    placeholder="Plan Duration"
                    options={planDuration}
                    isSearchable={false}
                    isClearable
                  />
                </Col>
                <Col xl="2" className="mb-3">
                  <Select
                    name="status"
                    placeholder="Filter By Status"
                    options={status}
                    isSearchable={false}
                    isClearable
                  />
                </Col>
                <Col xl="2" className="mb-3">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={filterPayment}
                  >
                    Filter
                  </Button>
                  <Button variant="danger" onClick={resetFilterPayment}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Col xl="12">
            <Table className="table-music mb-0 table" responsive>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Plan Name</th>
                  <th scope="col">Credits</th>
                  <th scope="col">Price</th>
                  <th scope="col">Sale Price</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>
                    Basic
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>$90</td>
                  <td>Dec 15, 2020</td>
                  <td>
                    <Badge bg="success" pill>
                      Active
                    </Badge>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-subscription"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="details"
                          as={Link}
                          to="/admin/edit-subscription"
                        >
                          <i className="ri-pencil-fill"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="important" as={Link} to="">
                          <i className="ri-delete-bin-6-line"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">2</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>$90</td>
                  <td>Dec 15, 2020</td>
                  <td>
                    <Badge bg="danger" pill>
                      Inactive
                    </Badge>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-subscription"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="details"
                          as={Link}
                          to="/admin/edit-subscription"
                        >
                          <i className="ri-pencil-fill"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="important" as={Link} to="">
                          <i className="ri-delete-bin-6-line"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">3</td>
                  <td>
                    Enterprise
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>$90</td>
                  <td>Dec 15, 2020</td>
                  <td>
                    <Badge bg="success" pill>
                      Active
                    </Badge>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-subscription"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="details"
                          as={Link}
                          to="/admin/edit-subscription"
                        >
                          <i className="ri-pencil-fill"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="important" as={Link} to="">
                          <i className="ri-delete-bin-6-line"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">1</td>
                  <td>
                    Basic
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>$90</td>
                  <td>Dec 15, 2020</td>
                  <td>
                    <Badge bg="success" pill>
                      Active
                    </Badge>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-subscription"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="details"
                          as={Link}
                          to="/admin/edit-subscription"
                        >
                          <i className="ri-pencil-fill"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="important" as={Link} to="">
                          <i className="ri-delete-bin-6-line"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">2</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>$90</td>
                  <td>Dec 15, 2020</td>
                  <td>
                    <Badge bg="danger" pill>
                      Inactive
                    </Badge>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-subscription"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="details"
                          as={Link}
                          to="/admin/edit-subscription"
                        >
                          <i className="ri-pencil-fill"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="important" as={Link} to="">
                          <i className="ri-delete-bin-6-line"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">3</td>
                  <td>
                    Enterprise
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>1000</td>
                  <td>$100</td>
                  <td>$90</td>
                  <td>Dec 15, 2020</td>
                  <td>
                    <Badge bg="success" pill>
                      Active
                    </Badge>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-subscription"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="details"
                          as={Link}
                          to="/admin/edit-subscription"
                        >
                          <i className="ri-pencil-fill"></i> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="important" as={Link} to="">
                          <i className="ri-delete-bin-6-line"></i> Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default SubscriptionList;
