import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import { Grid } from "gridjs-react";
import Select from "react-select";

import img1 from "../../../assets/img/img1.jpg";

function CompanySubUserLogs() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/company/subuser/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Logs
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>
          <Col xl="9">
          <div className="form-search py-2 mb-4">
              <i className="ri-search-line"></i>
              <Form.Control type="text" placeholder="Search activity" />
            </div>

            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5 className="section-title mb-0"></h5>
              <Form.Check
                type="switch"
                label="Show all activity"
                className="fs-sm"
              />
            </div>
            
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h5 className="section-title mb-0">Logs History</h5>
              <Link to="">Clear Searches</Link>
            </div>

            <ul className="activity-group mb-5">
              <li className="activity-date">Aug 30, 2023</li>
              <li className="activity-item search">
                <p className="d-sm-flex align-items-center mb-0">
                  <Link
                    to=""
                    className="avatar avatar-xs me-2 d-none d-sm-inline"
                  >
                    <img src={img1} alt="" />
                  </Link>
                  <span className="fs-sm">
                    <strong>You</strong> searched using a keyword{" "}
                    <strong>"company"</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">10:00am</span>
                </p>
              </li>
              <li className="activity-date">Aug 28, 2023</li>
              <li className="activity-item search">
                <p className="d-sm-flex align-items-center mb-0">
                  <Link
                    to=""
                    className="avatar avatar-xs me-2 d-none d-sm-inline"
                  >
                    <img src={img1} alt="" />
                  </Link>
                  <span className="fs-sm">
                    <strong>You</strong> searched using a keyword{" "}
                    <strong>"software engineer"</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">02:23pm</span>
                </p>
              </li>
              <li className="activity-item search">
                <p className="d-sm-flex align-items-center mb-0">
                  <Link
                    to=""
                    className="avatar avatar-xs me-2 d-none d-sm-inline"
                  >
                    <img src={img1} alt="" />
                  </Link>
                  <span className="fs-sm">
                    <strong>You</strong> searched using a keyword{" "}
                    <strong>"ui developer"</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">02:15pm</span>
                </p>
              </li>

              <li className="activity-date">Aug 30, 2023</li>
              <li className="activity-item search">
                <p className="d-sm-flex align-items-center mb-0">
                  <Link
                    to=""
                    className="avatar avatar-xs me-2 d-none d-sm-inline"
                  >
                    <img src={img1} alt="" />
                  </Link>
                  <span className="fs-sm">
                    <strong>You</strong> searched using a keyword{" "}
                    <strong>"company"</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">10:00am</span>
                </p>
              </li>
              <li className="activity-date">Aug 28, 2023</li>
              <li className="activity-item search">
                <p className="d-sm-flex align-items-center mb-0">
                  <Link
                    to=""
                    className="avatar avatar-xs me-2 d-none d-sm-inline"
                  >
                    <img src={img1} alt="" />
                  </Link>
                  <span className="fs-sm">
                    <strong>You</strong> searched using a keyword{" "}
                    <strong>"software engineer"</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">02:23pm</span>
                </p>
              </li>
              <li className="activity-item search">
                <p className="d-sm-flex align-items-center mb-0">
                  <Link
                    to=""
                    className="avatar avatar-xs me-2 d-none d-sm-inline"
                  >
                    <img src={img1} alt="" />
                  </Link>
                  <span className="fs-sm">
                    <strong>You</strong> searched using a keyword{" "}
                    <strong>"ui developer"</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">02:15pm</span>
                </p>
              </li>
            </ul>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanySubUserLogs;
