import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { levelsData } from "../../staticData";
import { FinancesData } from "../../staticData";
import { hrsData } from "../../staticData";
import { itsData } from "../../staticData";
import { legalsData } from "../../staticData";
import { marketingsData } from "../../staticData";
import { operationData } from "../../staticData";
import { procurementsData } from "../../staticData";
import { rdsData } from "../../staticData";
import { saleData } from "../../staticData";

function ContactRoleSearch() {
  //Level
  const levelData = levelsData
  //Department
  const FinanceData = FinancesData

  //Hr Dara
  const hrData = hrsData

  //IT Data
  const itData = itsData;

  //legals Data
  const legalData = legalsData

  //marketing Data
  const marketingData = marketingsData;

  //Operations Data
  const operationsData = operationData;

  //Procurements Data
  const procurementData = procurementsData;

  //rds Data
  const rdData = rdsData;

  //Sales Data
  const salesData = saleData;

  return (
    <React.Fragment>
      <Row className="mb-3">
        <label>
          <b>Contact Role</b>
        </label>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Level</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Check type="checkbox" label="Select All" />
              {levelData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Department</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Enter Job Department"
              />
              <br />
              {/* FinanceData */}
              <b>Finance</b>
              {FinanceData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* HR */}
              <b>HR</b>
              {hrData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* IT */}
              <b>IT</b>
              {itData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* Legal */}
              <b>Legal</b>
              {legalData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* Marketing */}
              <b>Marketing</b>
              {marketingData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* Operations */}
              <b>Operations</b>
              {operationsData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* Procurement */}
              <b>Procurement</b>
              {procurementData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* R&D */}
              <b>R&D</b>
              {rdData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
              <br />
              {/* Sales */}
              <b>Sales</b>
              {salesData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Title</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>Search by job title keywords, separated by commas.</p>
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="HRIS, benefits"
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </React.Fragment>
  );
}
export default ContactRoleSearch;
