import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./SaleTabbing.css";

function SaleTabbing() {
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();

  const tabCss = {
    color: "#666",
    fontSize: "17px",
  };

  const tabLinks = [
    {
      id: "tabbing_1",
      link: "/dashboard/uplead-intelligence",
      icon: "ri-home-4-line",
      title_1: "Sales",
      title_2: "Intelligence",
    },
    {
      id: "tabbing_2",
      link: "/dashboard/uplead-rod",
      icon: "ri-sticky-note-line",
      title_1: "Sales",
      title_2: "ROD",
    },
    {
      id: "tabbing_3",
      link: "/dashboard/uplead-saved-search",
      icon: "ri-align-center",
      title_1: "Saved",
      title_2: "Searches",
    },
    {
      id: "tabbing_4",
      link: "/dashboard/uplead-lists",
      icon: "ri-line-height",
      title_1: "Lists",
      title_2: "",
    },
    {
      id: "tabbing_5",
      link: "/dashboard/uplead-bounce",
      icon: "ri-indent-increase",
      title_1: "Bounce",
      title_2: "Intelligence",
    },
    {
      id: "tabbing_6",
      link: "/dashboard/uplead-visitor",
      icon: "ri-coupon-line",
      title_1: "Visitor",
      title_2: "Intelligence",
    },
  ];
  return (
    <React.Fragment>
      <Row className="g-3" style={{display:'none'}}>
        {tabLinks?.map((data, index) => {
          return (
            <Col className="col-lg-2 col-md-4 col-sm-6 col-12" key="">
              <Card
                className={`card-one ${
                  location.pathname == data.link && "tabbing_active"
                }`}
              >
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <label className="card-label fs-sm fw-medium mb-1">
                        <Link key={data.id} to={data.link}>
                          <h4 className="card-value mb-1">
                            <i className={data.icon}></i> {data.title_1}
                          </h4>
                          <h4 className="card-value ms-4">{data.title_2}</h4>
                        </Link>
                      </label>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          );
        })}

        {/* <Col sm="2" key="">
          <Card className="card-one">
            <Card.Body className="p-3">
              <div className="d-flex d-sm-block d-xl-flex align-items-center">
                <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                  <label className="card-label fs-sm fw-medium mb-1">
                    <Link to="/dashboard/uplead-intelligence">
                      <h4 className="card-value mb-1">
                        <i className="ri-home-4-line"></i> Sales
                      </h4>
                      <h4 className="card-value ms-4">Intelligence</h4>
                    </Link>
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="2" key="">
          <Card className="card-one">
            <Card.Body className="p-3">
              <div className="d-flex d-sm-block d-xl-flex align-items-center">
                <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                  <label className="card-label fs-sm fw-medium mb-1">
                    <Link to="/dashboard/uplead-rod">
                      <h4 className="card-value mb-1">
                        <i className="ri-sticky-note-line"></i> Sales
                      </h4>
                      <h4 className="card-value ms-4">ROD</h4>
                    </Link>
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="2" key="">
          <Card className="card-one">
            <Card.Body className="p-3">
              <div className="d-flex d-sm-block d-xl-flex align-items-center">
                <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                  <label className="card-label fs-sm fw-medium mb-1">
                    <Link to="/dashboard/uplead-saved-search">
                      <h4 className="card-value mb-1">
                        <i className="ri-align-center"></i> Saved
                      </h4>
                      <h4 className="card-value ms-4">Searches</h4>
                    </Link>
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="2" key="">
          <Card className="card-one">
            <Card.Body className="p-3">
              <div className="d-flex d-sm-block d-xl-flex align-items-center">
                <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                  <label className="card-label fs-sm fw-medium mb-1">
                    <Link to="/dashboard/uplead-lists">
                      <h4 className="card-value mb-1">
                        <i className="ri-line-height"></i> Lists
                      </h4>
                    </Link>
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="2" key="">
          <Card className="card-one">
            <Card.Body className="p-3">
              <div className="d-flex d-sm-block d-xl-flex align-items-center">
                <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                  <label className="card-label fs-sm fw-medium mb-1">
                    <Link to="/dashboard/uplead-bounce">
                      <h4 className="card-value mb-1">
                        <i className="ri-indent-increase"></i> Bounce
                      </h4>
                      <h4 className="card-value ms-4">Intelligence</h4>
                    </Link>
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm="2" key="">
          <Card className="card-one">
            <Card.Body className="p-3">
              <div className="d-flex d-sm-block d-xl-flex align-items-center">
                <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                  <label className="card-label fs-sm fw-medium mb-1">
                    <Link to="/dashboard/uplead-visitor">
                      <span class="main-title" style={tabCss}>
                        <h4 className="card-value mb-1">
                          <i className="ri-coupon-line"></i> Visitor
                        </h4>
                        <h4 className="card-value mb-1 ms-4">Intelligence</h4>
                      </span>
                    </Link>
                  </label>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </React.Fragment>
  );
}

export default SaleTabbing;
