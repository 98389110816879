import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Select from "react-select";

function CompanySubUserDashboard() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [key, setKey] = useState("Companies");

  const chartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
        backgroundColor: "#506fd9",
        barPercentage: 0.5,
      },
      {
        data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
        backgroundColor: "#85b6ff",
        barPercentage: 0.5,
      },
    ],
  };

  const chartOption = {
    indexAxis: "y",
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: "#000",
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 10,
            weight: "500",
          },
        },
      },
      y: {
        grid: {
          borderWidth: 0,
          color: "#f3f5f9",
        },
        ticks: {
          color: "#212830",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const regStyle = {
    selected: {
      fill: "#506fd9",
    },
    initial: {
      fill: "#d9dde7",
    },
  };

  const planType = [
    {
      value: "basic",
      label: "Basic",
    },
    { value: "pro", label: "Pro" },
    { value: "enterprise", label: "Enterprise" },
  ];

  const typeDurations = [
    {
      value: "1",
      label: "Monthly",
    },
    { value: "2", label: "Yearly" },
  ];
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
            <h4 className="main-title mb-0">Welcome, User_Name</h4>
          </div>

          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-share-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-printer-line"></i>
              </Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href="">
                <i className="ri-bar-chart-2-line"></i>
              </Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Row className="g-3">
          <Col xl="5">
            <Row className="g-3">
              {[
                {
                  icon: "ri-file-search-line",
                  percent: {
                    color: "success",
                    amount: "",
                  },
                  value: "10",
                  label: "Saved Searches",
                  last: {
                    color: "success",
                    amount: "",
                  },
                },
                {
                  icon: "ri-file-search-line",
                  percent: {
                    color: "success",
                    amount: "",
                  },
                  value: "10",
                  label: "Saved Lists",
                  last: {
                    color: "success",
                    amount: "",
                  },
                },
                {
                  icon: "ri-html5-line",
                  percent: {
                    color: "success",
                    amount: "",
                  },
                  value: "100",
                  label: "Last Login Details",
                  last: {
                    color: "success",
                    amount: "",
                  },
                },
                {
                  icon: "ri-list-check-2",
                  percent: {
                    color: "success",
                    amount: "",
                  },
                  value: "Basic",
                  label: "Your Plan",
                  last: {
                    color: "success",
                    amount: "",
                  },
                },
              ].map((item, index) => (
                <Col xs="6" md="3" xl="6" key={index}>
                  <Card className="card-one card-product">
                    <Card.Body className="p-3">
                      <div className="d-flex align-items-center justify-content-between mb-5">
                        <div className="card-icon">
                          <i className={item.icon}></i>
                        </div>
                        <h6
                          className={
                            "fw-normal ff-numerals mb-0 text-" +
                            item.percent.color
                          }
                        >
                          {item.percent.amount}
                        </h6>
                      </div>
                      <h2 className="card-value ls--1">{item.value}</h2>
                      <label className="card-label fw-medium text-dark">
                        {item.label}
                      </label>
                      <span className="d-flex gap-1 fs-xs">
                        <span
                          className={
                            "d-flex align-items-center text-" + item.last.color
                          }
                        >
                          <span className="ff-numerals">
                            {item.last.amount}
                          </span>
                        </span>
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col xl="7">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Credit at Glance</Card.Title>
                <Nav as="nav" className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                <Row className="g-3 mb-4">
                  {[
                    {
                      bg: "primary",
                      icon: "ri-medal-2-line",
                      value: "5,000",
                      label: "Total Credits",
                    },
                    {
                      bg: "twitter",
                      icon: "ri-pie-chart-line",
                      value: "2,000",
                      label: "Credits Used",
                    },
                    {
                      bg: "success",
                      icon: "ri-line-chart-fill",
                      value: "3,000",
                      label: "Credits Remaining",
                    },
                  ].map((item, index) => (
                    <Col key={index}>
                      <div className="earning-item">
                        <div className={"earning-icon bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </div>
                        <h4>{item.value}</h4>
                        <label>{item.label}</label>
                      </div>
                    </Col>
                  ))}
                </Row>

                <Table className="table-two mb-4" responsive>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Credits Count</th>
                      <th>Credits Used</th>
                      <th>Credit Remaining</th>
                      <th>% Usage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      {
                        date: "03/05/2023",
                        sales: "3175",
                        earnings: "175",
                        tax: "3000",
                        percent: "3.5%",
                      },
                      {
                        date: "03/04/2023",
                        sales: "3250",
                        earnings: "75",
                        tax: "3175",
                        percent: "1.5%",
                      },
                      {
                        date: "03/04/2023",
                        sales: "3400",
                        earnings: "150",
                        tax: "3250",
                        percent: "3.0%",
                      },
                      {
                        date: "03/02/2023",
                        sales: "3500",
                        earnings: "100",
                        tax: "3400",
                        percent: "2.0%",
                      },
                      {
                        date: "02/28/2023",
                        sales: "3700",
                        earnings: "200",
                        tax: "3500",
                        percent: "4.0%",
                      },
                    ].map((item, index) => (
                      <tr key={index}>
                        <td>{item.date}</td>
                        <td>{item.sales}</td>
                        <td className="text-success">{item.earnings}</td>
                        <td className="text-danger">{item.tax}</td>
                        <td>{item.percent}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Recent Searches</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-shopping-cart-line",
                      label: "Search 1",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "info",
                      icon: "ri-coins-line",
                      label: "Search 2",
                      date: "Oct 19, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "primary",
                      icon: "ri-truck-line",
                      label: "Search 3",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "pink",
                      icon: "ri-truck-line",
                      label: "Search 4",
                      date: "Oct 18, 2023, 12:40pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "secondary",
                      icon: "ri-secure-payment-line",
                      label: "Search 5",
                      date: "Oct 15, 2023, 08:09am",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link
                  to="/dashboard/uplead-saved-search"
                  className="fs-sm"
                >
                  View All
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Saved Searches</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-shopping-cart-line",
                      label: "Search 1",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "info",
                      icon: "ri-coins-line",
                      label: "Search 2",
                      date: "Oct 19, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "primary",
                      icon: "ri-truck-line",
                      label: "Search 3",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "pink",
                      icon: "ri-truck-line",
                      label: "Search 4",
                      date: "Oct 18, 2023, 12:40pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "secondary",
                      icon: "ri-secure-payment-line",
                      label: "Search 5",
                      date: "Oct 15, 2023, 08:09am",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link
                  to="/dashboard/uplead-saved-search"
                  className="fs-sm"
                >
                  View All
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Saved Lists</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {[
                    {
                      bg: "teal",
                      icon: "ri-shopping-cart-line",
                      label: "List 1",
                      date: "Oct 21, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "info",
                      icon: "ri-coins-line",
                      label: "List 2",
                      date: "Oct 19, 2023, 3:30pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "primary",
                      icon: "ri-truck-line",
                      label: "List 3",
                      date: "Oct 18, 2023, 6:18pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "pink",
                      icon: "ri-truck-line",
                      label: "List 4",
                      date: "Oct 18, 2023, 12:40pm",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                    {
                      bg: "secondary",
                      icon: "ri-secure-payment-line",
                      label: "List 5",
                      date: "Oct 15, 2023, 08:09am",
                      value: "",
                      status: "View",
                      color: "success",
                    },
                  ].map((item, index) => (
                    <li className="people-item" key={index}>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                      </div>
                      <div className="people-body">
                        <h6>
                          <Link to="">{item.label}</Link>
                        </h6>
                        <span>{item.date}</span>
                      </div>
                      <div className="text-end">
                        <div className="fs-sm">{item.value}</div>
                        <span className={"d-block fs-xs text-" + item.color}>
                          {item.status}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link
                  to="/dashboard/uplead-saved-search"
                  className="fs-sm"
                >
                  View All
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default CompanySubUserDashboard;
