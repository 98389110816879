import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Badge,
  Dropdown,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../../layouts/Header";
import ReactDatePicker from "react-datepicker";
import { Grid } from "gridjs-react";
import Select from "react-select";
import Footer from "../../../layouts/Footer";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

function PaymentList(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [filterBy, setFilterBy] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //Filter Function
  const filterPayment = (e) => {
    const postdata = {
      keyword: filterBy,
      start_date: startDate,
      end_date: endDate,
    };
    console.log(postdata);
  };

  //Reset
  const resetFilterPayment = (e) => {
    const postdata = {
      keyword: "",
      start_date: "",
      end_date: "",
    };
    console.log(postdata);
    setFilterBy("");
    setStartDate("");
    setEndDate("");
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const filterByType = [
    {
      value: "1",
      label: "Monthly",
    },
    { value: "2", label: "Yearly" },
  ];

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Row className="g-3">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Payment List
                </li>
              </ol>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0"></div>
          </div>

          <Card className="card">
            <Card.Body>
              <Row>
                <Col xl="2" className="mt-3">
                  <Form.Control
                    type="text"
                    id="searchbox"
                    placeholder="Filter by Transaction ID"
                    onChange={(e) => setFilterBy(e.target.value)}
                    value={filterBy}
                  />
                </Col>
                <Col xl="2" className="mt-3">
                  <Form.Control
                    type="text"
                    id="searchbox"
                    placeholder="Filter by User Name"
                  />
                </Col>
                <Col xl="2" className="mt-3">
                  <Form.Control
                    type="text"
                    id="searchbox"
                    placeholder="Filter by User Email"
                  />
                </Col>
                <Col xl="2" className="mt-3">
                  <Select
                    name="user_role"
                    placeholder="Filter By Type"
                    options={filterByType}
                    isSearchable={false}
                    isClearable
                  />
                </Col>
                <Col xl="2" className="mt-3">
                  <DateRangePicker>
                    <Form.Control
                      type="text"
                      id="date_range"
                      placeholder="Select date range"
                      onChange=""
                      value=""
                    />
                  </DateRangePicker>
                </Col>
                <Col xl="2" className="mt-3">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={filterPayment}
                  >
                    Filter
                  </Button>
                  <Button variant="danger" onClick={resetFilterPayment}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* <Col xl="12">
            <Card className="card">
              <Card.Body>
                 <Table className="mb-0" striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Transaction ID</th>
                      <th scope="col">Full Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment At</th>
                      <th scope="col">Payment Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>TAX-HTSFHJH</td>
                      <td>
                        <Link to="">Jay Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Dec 15, 2020</td>
                      <td>
                        <Badge bg="success" pill>
                          Success
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">2</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">Maliya Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Dec 31, 2020</td>
                      <td>
                        <Badge bg="danger" pill>
                          Cancel
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">3</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">OM Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Jan 01, 2021</td>
                      <td>
                        <Badge bg="secondary" pill>
                          Pending
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">4</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">Martin Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Jan 05, 2021</td>
                      <td>
                        <Badge bg="danger" pill>
                          Faild
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">5</th>
                      <td>TAX-HTSFHJH</td>
                      <td>
                        <Link to="">Jay Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Dec 15, 2020</td>
                      <td>
                        <Badge bg="success" pill>
                          Success
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">6</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">Maliya Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Dec 31, 2020</td>
                      <td>
                        <Badge bg="danger" pill>
                          Cancel
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">7</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">OM Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Jan 01, 2021</td>
                      <td>
                        <Badge bg="secondary" pill>
                          Pending
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">8</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">Martin Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Jan 05, 2021</td>
                      <td>
                        <Badge bg="danger" pill>
                          Faild
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">9</th>
                      <td>TAX-HTSFHJH</td>
                      <td>
                        <Link to="">Jay Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Dec 15, 2020</td>
                      <td>
                        <Badge bg="success" pill>
                          Success
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">10</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">Maliya Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Dec 31, 2020</td>
                      <td>
                        <Badge bg="danger" pill>
                          Cancel
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">11</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">OM Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Jan 01, 2021</td>
                      <td>
                        <Badge bg="secondary" pill>
                          Pending
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">12</th>
                      <td>TAX-HTSFHJHdd</td>
                      <td>
                        <Link to="">Martin Thomas</Link>
                      </td>
                      <td>$10</td>
                      <td>Jan 05, 2021</td>
                      <td>
                        <Badge bg="danger" pill>
                          Faild
                        </Badge>
                      </td>
                      <td>
                        <div
                          className="btn-toolbar"
                          role="toolbar"
                          aria-label="Toolbar with button groups"
                        >
                          <ButtonGroup
                            aria-label="Third group"
                            className="me-2"
                          >
                            <Link
                              to="/admin/view-payment"
                              className="btn btn-primary"
                            >
                              <i className="ri-eye-fill"></i>
                            </Link>
                          </ButtonGroup>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table> 
              </Card.Body>
            </Card>
          </Col> */}

          <Col xl="12">
            <Table className="table-music mb-0 table" responsive>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Plan Details</th>
                  <th scope="col">User Details</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Payment Date</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">1</td>
                  <td>Purchase from #10322</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>
                    Amazon
                    <span className="d-block fs-xs text-success">Company</span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-success">
                      Completed
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-payment"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">2</td>
                  <td>Process refund to #00910</td>
                  <td>
                    Basic
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>
                    Jay Thomas
                    <span className="d-block fs-xs text-success">
                      Company Sub User
                    </span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-warning">
                      Processing
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-payment"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">3</td>
                  <td>Payment failed #087651</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>
                    Jay Thomas
                    <span className="d-block fs-xs text-success">
                      Demo User
                    </span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-danger">Declined</span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-payment"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>

                <tr>
                  <td scope="row">4</td>
                  <td>Purchase from #10322</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>
                    Jay Thomas
                    <span className="d-block fs-xs text-success">Company</span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-success">
                      Completed
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-payment"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">5</td>
                  <td>Process refund to #00910</td>
                  <td>
                    Basic
                    <span className="d-block fs-xs text-success">Monthly</span>
                  </td>
                  <td>
                    Jay Thomas
                    <span className="d-block fs-xs text-success">
                      Company Sub User
                    </span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-warning">
                      Processing
                    </span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-payment"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td scope="row">6</td>
                  <td>Payment failed #087651</td>
                  <td>
                    Pro
                    <span className="d-block fs-xs text-success">Yearly</span>
                  </td>
                  <td>
                    Apple
                    <span className="d-block fs-xs text-success">
                      Enterprise
                    </span>
                  </td>
                  <td>100</td>
                  <td>Oct 21, 2023, 3:30pm</td>
                  <td>
                    <span className="d-block fs-xs text-danger">Declined</span>
                  </td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="important"
                          as={Link}
                          to="/admin/view-payment"
                        >
                          <i className="ri-eye-fill"></i> View
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
            <br />
            <Pagination className="justify-content-center mb-0">
              <Pagination.Item disabled>Previous</Pagination.Item>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>Next</Pagination.Item>
            </Pagination>
          </Col>

          {/* <Col xl="12">
            <Card className="card-one">
              <Card.Body className="p-0">
                <ul className="people-group">
                  <li className="people-item">
                    <div className="avatar">1.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-teal">
                        <i className="ri-shopping-cart-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Purchase from #10322 | </Link>
                        <Link to="">Jay Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">+$250.00</div>
                      <span className="d-block fs-xs text-success">
                        Completed
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">2.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-info">
                        <i className="ri-coins-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Process refund to #00910 | </Link>
                        <Link to="">Maliya Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">-$250.00</div>
                      <span className="d-block fs-xs text-warning">
                        Processing
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">3.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-secondary">
                        <i className="ri-secure-payment-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Payment failed #087651 | </Link>
                        <Link to="">Martin Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">$150.20</div>
                      <span className="d-block fs-xs text-danger">
                        Declined
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">4.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-teal">
                        <i className="ri-shopping-cart-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Purchase from #10322 | </Link>
                        <Link to="">Jay Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">+$250.00</div>
                      <span className="d-block fs-xs text-success">
                        Completed
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">5.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-info">
                        <i className="ri-coins-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Process refund to #00910 | </Link>
                        <Link to="">Maliya Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">-$250.00</div>
                      <span className="d-block fs-xs text-warning">
                        Processing
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">6.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-secondary">
                        <i className="ri-secure-payment-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Payment failed #087651 | </Link>
                        <Link to="">Martin Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">$150.20</div>
                      <span className="d-block fs-xs text-danger">
                        Declined
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">7.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-teal">
                        <i className="ri-shopping-cart-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Purchase from #10322 | </Link>
                        <Link to="">Jay Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">+$250.00</div>
                      <span className="d-block fs-xs text-success">
                        Completed
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">8.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-info">
                        <i className="ri-coins-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Process refund to #00910 | </Link>
                        <Link to="">Maliya Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">-$250.00</div>
                      <span className="d-block fs-xs text-warning">
                        Processing
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="people-item">
                    <div className="avatar">9.</div>
                    <div className="avatar">
                      <span className="avatar-initial fs-20 bg-secondary">
                        <i className="ri-secure-payment-line"></i>
                      </span>
                    </div>
                    <div className="people-body">
                      <h6>
                        <Link to="">Payment failed #087651 | </Link>
                        <Link to="">Martin Thomas</Link>
                      </h6>
                      <span>Oct 21, 2023, 3:30pm</span>
                    </div>
                    <div className="text-end">
                      <div className="fs-sm">$150.20</div>
                      <span className="d-block fs-xs text-danger">
                        Declined
                      </span>
                    </div>
                    <div className="text-end">
                      <Dropdown align="end" className="dropdown-file">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to="/admin/view-payment"
                            className="details"
                          >
                            <i className="ri-information-line"></i> View Details
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Pagination className="justify-content-center mb-0">
                  <Pagination.Item disabled>Previous</Pagination.Item>
                  <Pagination.Item active>1</Pagination.Item>
                  <Pagination.Item>2</Pagination.Item>
                  <Pagination.Item>3</Pagination.Item>
                  <Pagination.Item>Next</Pagination.Item>
                </Pagination>
              </Card.Footer>
            </Card>
          </Col> */}
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default PaymentList;
