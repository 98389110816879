export const loginUsers = [
  {
    name: "Super Admin",
    role: "super-admin",
    email: "admin@yopmail.com",
    password: "123456",
  },
  {
    name: "Company",
    role: "company",
    email: "companygau@yopmail.com",
    password: "123456",
  },
  {
    name: "Company User",
    role: "company-user",
    email: "companyuser@yopmail.com",
    password: "123456",
  },
  {
    name: "Demo User",
    role: "demo-user",
    email: "demouser@yopmail.com",
    password: "123456",
  },
];

export const topics = [
  { value: "", label: "Accenture (ACN)" },
  { value: "", label: "AECOM Technology (ACM)" },
  { value: "", label: "Anti-CCP (Anti-Cyclic Citrullinated Peptide) Test" },
  { value: "", label: "Antinuclear Antibody (ANA) Testing" },
  { value: "", label: "Automatic Data Processing (ADP)" },
  { value: "", label: "Backup and Recovery" },
  { value: "", label: "Biofuel" },
  { value: "", label: "Biomass" },
  { value: "", label: "Blood-Borne Disease Assays" },
  { value: "", label: "C-Reactive Protein (CRP) Test" },
  { value: "", label: "Claims Processing" },
  { value: "", label: "Climate-Smart Agriculture" },
  { value: "", label: "Complement Fixation Test" },
  { value: "", label: "Complete Blood Count (CBC)" },
  { value: "", label: "CoreHR" },

  { value: "", label: "Cost of sales" },
  { value: "", label: "Data Deduplication" },
  { value: "", label: "Data Distribution" },
  { value: "", label: "Data Driven Sales" },
  { value: "", label: "Data Integration" },
  { value: "", label: "Data Loss Prevention" },
  { value: "", label: "Defense Attorney" },
  { value: "", label: "Direct Response (DR)" },
  { value: "", label: "Dongle" },
  { value: "", label: "Due Diligence" },
  { value: "", label: "Electrical Substation" },
  { value: "", label: "Erythrocyte Sedimentation Rate (ESR Rate)" },
  { value: "", label: "Experian (EXPN)" },
  { value: "", label: "Firewire" },
  { value: "", label: "Flickr" },
  { value: "", label: "Flipboard" },

  { value: "", label: "FTI Consulting (FCN)" },
  { value: "", label: "Gaming Design" },
  { value: "", label: "Gartner (IT)" },
  { value: "", label: "Glucose Testing" },
  { value: "", label: "Google Groups" },
  { value: "", label: "Google+" },
  { value: "", label: "Hashtag" },
  { value: "", label: "Healthcare and Practice Management" },
  { value: "", label: "Healthcare Providers" },
  { value: "", label: "Hospital Budgeting" },
  { value: "", label: "HP PageWide" },
  { value: "", label: "HP SureSupply" },
  { value: "", label: "Hulu" },
  { value: "", label: "Implied Terms" },
  { value: "", label: "Influenster" },
  { value: "", label: "Infosys (INFY)" },
  { value: "", label: "Ink Cartridge" },
  { value: "", label: "Instagram" },

  { value: "", label: "Large Group Health Plan" },
  { value: "", label: "Laser Printers" },
  {
    value: "",
    label: "Leadership In Energy and Environmental Design (LEED)",
  },
  { value: "", label: "Legal" },
  { value: "", label: "Legal Process Outsourcing" },
  { value: "", label: "LinkedIn" },
  { value: "", label: "Long-Range Planning" },
  { value: "", label: "Marketing Campaign" },
  { value: "", label: "Massively Multiplayer Online Game" },
  { value: "", label: "Medical Billing" },
  { value: "", label: "Medical Coding" },
  { value: "", label: "Meetup" },
  { value: "", label: "Microbiology Testing" },
  { value: "", label: "Mobile Printing" },
  { value: "", label: "Monitoring" },
  { value: "", label: "Multiplex Immunoassays" },
  { value: "", label: "Netflix (NFLX)" },
  { value: "", label: "Newborn Screening" },

  { value: "", label: "Online Gaming" },
  { value: "", label: "Physician Compensation" },
  { value: "", label: "Pinterest" },
  { value: "", label: "PricewaterhouseCoopers (PwC)" },
  { value: "", label: "Primary Care" },
  { value: "", label: "Qzone" },
  { value: "", label: "Sales Goals" },
  { value: "", label: "Sales Training" },
  { value: "", label: "Salesforce Management System" },
  { value: "", label: "Search and Retrieval" },
  { value: "", label: "Security Monitoring" },
  { value: "", label: "Sina Weibo (WB)" },
  { value: "", label: "Sitecore" },
  { value: "", label: "Snapchat" },
  { value: "", label: "Social Listening" },
  { value: "", label: "Social Media" },
  { value: "", label: "Social Networking" },
  { value: "", label: "SocialFlow" },

  { value: "", label: "Substance Abuse Evaluations" },
  { value: "", label: "Territory Planning" },
  { value: "", label: "Third-Party Vendors" },
  { value: "", label: "Thunderbolt" },
  { value: "", label: "Tumblr" },
  { value: "", label: "Vevo" },
  { value: "", label: "Vine" },
  { value: "", label: "Virtual Worlds" },
  { value: "", label: "WhatsApp" },
  { value: "", label: "Windmills" },
  { value: "", label: "WordPress" },
  { value: "", label: "Workhuman" },
  { value: "", label: "Yelp" },
  { value: "", label: "YouTube" },
  { value: "", label: "Zendesk (ZEN)" },
];

export const IndustryData = [
  { value: "", label: "Agriculture, Forestry, Fishing and Hunting" },
  { value: "", label: "Crop Farming" },
  { value: "", label: "Dairy & Animal Farming" },
  { value: "", label: "Forestry & Logging" },
  { value: "", label: "Fishing, Hunting & Trapping" },
  { value: "", label: "Support Activities for Agriculture and Forestry" },
  { value: "", label: "Agriculture, Forestry, Fishing and Hunting" },
  { value: "", label: "Arts, Entertainment, and Recreation" },
  { value: "", label: "Performing Arts Companies" },
  { value: "", label: "Sports" },

  {
    value: "",
    label: "Promoters of Performing Arts, Sports, and Similar Events",
  },
  {
    value: "",
    label:
      "Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures",
  },
  { value: "", label: "Independent Artists, Writers, and Performers" },
  { value: "", label: "Museums, Historical Sites, and Similar Institutions" },
  { value: "", label: "Amusement Parks and Arcades" },
  { value: "", label: "Casinos & Gambling Services" },
  { value: "", label: "Other Amusement and Recreation Industries" },
  { value: "", label: "Arts, Entertainment, and Recreation" },
  { value: "", label: "Business Support Services" },
  { value: "", label: "Management of Companies and Enterprises" },
  { value: "", label: "Office Administrative Services" },
  { value: "", label: "Facilities Support Services" },
  { value: "", label: "Human Resources, Staffing & Recruiting" },
  { value: "", label: "Other Support Services" },
  { value: "", label: "Debt Collections" },
  { value: "", label: "Call Centers" },

  { value: "", label: "Travel Arrangement and Reservation Services" },
  { value: "", label: "Investigation and Security Services" },
  { value: "", label: "Waste Collection, Treatment and Disposal" },
  { value: "", label: "Business Support Services" },
  { value: "", label: "Construction" },
  { value: "", label: "Residential Building Construction" },
  { value: "", label: "Commercial Building Construction" },
  { value: "", label: "Utility System Construction" },
  { value: "", label: "Land Subdivision" },
  { value: "", label: "Highway, Street, and Bridge Construction" },
  { value: "", label: "Other Heavy and Civil Engineering Construction" },
  {
    value: "",
    label: "Foundation, Structure, and Building Exterior Contractors",
  },
  { value: "", label: "Building Equipment Contractors" },
  { value: "", label: "Building Finishing Contractors" },
  { value: "", label: "Other Specialty Trade Contractors" },
  { value: "", label: "Construction" },

  { value: "", label: "Educational Services" },
  { value: "", label: "Elementary and Secondary Schools" },
  { value: "", label: "Junior Colleges" },
  { value: "", label: "Colleges, Universities, and Professional Schools" },
  {
    value: "",
    label: "Business Schools and Computer and Management Training",
  },
  { value: "", label: "Technical and Trade Schools" },
  { value: "", label: "Other Schools and Instruction" },
  { value: "", label: "Educational Support Services" },
  { value: "", label: "Educational Services" },
  { value: "", label: "Energy, Utilities and Oil & Gas" },
  { value: "", label: "Oil and Gas Extraction" },
  { value: "", label: "Coal Mining" },
  { value: "", label: "Metal Ore Mining" },

  { value: "", label: "Nonmetallic Mineral Mining and Quarrying" },
  { value: "", label: "Support Activities for Mining" },
  {
    value: "",
    label: "Electric Power Generation, Transmission and Distribution",
  },
  { value: "", label: "Natural Gas Distribution" },
  { value: "", label: "Water, Sewage and Other Systems" },
  { value: "", label: "Pipeline Transportation of Crude Oil" },
  { value: "", label: "Pipeline Transportation of Natural Gas" },
  { value: "", label: "Other Pipeline Transportation" },
  { value: "", label: "Energy, Utilities and Oil & Gas" },
  { value: "", label: "Federal, State, and Local Government" },
  { value: "", label: "Federal Government" },
  { value: "", label: "State Government, excluding schools and hospitals" },
  { value: "", label: "Local Government, excluding schools and hospitals" },
  { value: "", label: "Federal, State, and Local Government" },
  { value: "", label: "Finance and Insurance" },
  { value: "", label: "Monetary Authorities-Central Bank" },
  { value: "", label: "Credit Unions & Depository Credit Intermediation" },
  { value: "", label: "Banking" },

  { value: "", label: "Nondepository Credit Intermediation" },
  { value: "", label: "Credit Card Issuing & Transaction Processing" },
  { value: "", label: "Activities Related to Credit Intermediation" },
  { value: "", label: "Securities and Commodity Exchanges and Services" },
  { value: "", label: "Investment Banking and Securities Dealing" },
  { value: "", label: "Other Financial Investment Activities" },
  { value: "", label: "Venture Capital & Private Equity" },
  { value: "", label: "Insurance Carriers" },

  { value: "", label: "Health Insurance Carriers" },
  { value: "", label: "Insurance Agencies and Brokers" },
  { value: "", label: "Insurance and Employee Benefit Funds" },
  { value: "", label: "Other Investment Pools and Funds" },
  { value: "", label: "Finance and Insurance" },
  { value: "", label: "Health Care and Social Assistance" },
  { value: "", label: "Doctors and Dentists Offices" },
  { value: "", label: "Outpatient Surgery Centers and Emergency Rooms" },
  {
    value: "",
    label: "Medical Testing and Clinical & Diagnostic Laboratories",
  },
  { value: "", label: "Home Health Care & Hospice Services" },
  { value: "", label: "Other Health Care Services" },
  { value: "", label: "Hospitals and Emergency Rooms" },
  { value: "", label: "Skilled Nursing Facilities" },

  {
    value: "",
    label:
      "Nursing Homes, Retirement Communities & Residential Care Facilities",
  },
  { value: "", label: "Individual, Family, and Child Care Services" },
  {
    value: "",
    label:
      "Community Food and Housing, and Emergency and Other Relief Services",
  },
  { value: "", label: "Vocational Rehabilitation Services" },
  { value: "", label: "Health Care and Social Assistance" },
  { value: "", label: "Hospitality and Food Services" },
  { value: "", label: "Hotels and Traveler Accommodation" },
  {
    value: "",
    label: "RV (Recreational Vehicle) Parks and Recreational Camps",
  },
  { value: "", label: "Special Food Services & Caterers" },
  { value: "", label: "Bars and Restaurants" },
  { value: "", label: "Hospitality and Food Services" },
  { value: "", label: "Information Technology" },
  {
    value: "",
    label: "Computer Hardware and Electronic Product Manufacturing",
  },
  { value: "", label: "Computer Networking & Telecommunications Equipment" },
  { value: "", label: "Software Development and Design" },
  { value: "", label: "Telecommunications" },
  {
    value: "",
    label:
      "Cloud Computing, Data Processing & Storage, Hosting, and Related Services",
  },
  { value: "", label: "Internet Service Providers and Web Search Portals" },
  { value: "", label: "Financial Software" },
  { value: "", label: "Legal Software" },
  { value: "", label: "Engineering Software & Engineering Services" },
  {
    value: "",
    label: "Multimedia, Games, Graphics Software & Graphic Design Services",
  },
  { value: "", label: "Computer Systems Design and Related Services" },
  { value: "", label: "Human Resources Software" },
  {
    value: "",
    label: "Supply Chain and Logistics Software & Consulting Services",
  },
  { value: "", label: "Enterprise Resource Planning Software" },
  { value: "", label: "Information Security & Security Systems Services" },
  { value: "", label: "Information Technology" },
  { value: "", label: "Manufacturing" },
  { value: "", label: "Food, Beverage, and Tobacco Manufacturing" },
  { value: "", label: "Textile & Apparel Manufacturing" },
  { value: "", label: "Wood Product Manufacturing" },
  { value: "", label: "Paper Manufacturing" },
  { value: "", label: "Printing and Related Support Activities" },
  { value: "", label: "Petroleum and Coal Products Manufacturing" },
  { value: "", label: "Chemical Manufacturing" },
  { value: "", label: "Pharmaceutical and Medicine Manufacturing" },
  { value: "", label: "Plastics and Rubber Products Manufacturing" },
  { value: "", label: "Nonmetallic Mineral Product Manufacturing" },
  { value: "", label: "Primary Metal Manufacturing" },
  { value: "", label: "Fabricated Metal Product Manufacturing" },
  { value: "", label: "Machinery Manufacturing" },
  { value: "", label: "Industrial Automation" },
  {
    value: "",
    label: "Electrical Equipment, Appliance, and Component Manufacturing",
  },
  { value: "", label: "Automobile & Auto Parts Manufacturing" },
  { value: "", label: "Aerospace, Aviation & Defense Manufacturing" },
  { value: "", label: "Transportation Equipment Manufacturing" },
  { value: "", label: "Furniture Manufacturing" },
  { value: "", label: "Medical Device Manufacturing" },
  { value: "", label: "Other Miscellaneous Manufacturing" },
  { value: "", label: "Manufacturing" },
  { value: "", label: "Media" },
  {
    value: "",
    label: "Newspaper, Periodical, Book, and Directory Publishers",
  },
  {
    value: "",
    label:
      "Movies, Music, Television, Radio, and Subscription Broadcasting & Programming",
  },
  { value: "", label: "Social Media & Other Information Services" },
  { value: "", label: "Other Services (except Public Administration)" },
  { value: "", label: "Automotive Repair and Maintenance" },
  {
    value: "",
    label: "Electronic and Precision Equipment Repair and Maintenance",
  },
  {
    value: "",
    label:
      "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance",
  },
  { value: "", label: "Personal and Household Goods Repair and Maintenance" },
  { value: "", label: "Personal Care Services" },
  { value: "", label: "Death Care & Funeral Services" },
  { value: "", label: "Drycleaning and Laundry Services" },
  { value: "", label: "Religious Organizations" },
  { value: "", label: "Nonprofit and Charitable Organizations" },
  { value: "", label: "Social Advocacy Organizations" },
  { value: "", label: "Civic and Social Organizations" },
  {
    value: "",
    label:
      "Business, Professional, Labor, Political, and Membership Organizations",
  },
  { value: "", label: "Other Services (except Public Administration)" },
  { value: "", label: "Professional & Consumer Services" },
  { value: "", label: "Law Firms & Legal Services" },
  {
    value: "",
    label: "Accounting, Tax Preparation, Bookkeeping, and Payroll Services",
  },
  { value: "", label: "Architectural, Engineering, and Related Services" },
  { value: "", label: "Specialized Design Services" },
  {
    value: "",
    label: "Other Professional, Scientific, and Technical Services",
  },
  { value: "", label: "Biotechnology Research & Development" },
  {
    value: "",
    label: "Marketing, Advertising, Public Relations, and Related Services",
  },
  { value: "", label: "Veterinary Services" },
  { value: "", label: "Professional & Consumer Services" },
  { value: "", label: "Public Administration" },
  {
    value: "",
    label: "Executive, Legislative, and Other General Government Support",
  },
  { value: "", label: "Justice, Public Order, and Safety Activities" },
  { value: "", label: "Administration of Human Resource Programs" },
  { value: "", label: "Administration of Environmental Quality Programs" },
  {
    value: "",
    label:
      "Administration of Housing Programs, Urban Planning, and Community Development",
  },
  { value: "", label: "Administration of Economic Programs" },
  {
    value: "",
    label: "Aerospace & Defense Research and Technology Services",
  },
  { value: "", label: "National Security and International Affairs" },
  { value: "", label: "Public Administration" },
  { value: "", label: "Real Estate and Rental & Leasing" },
  { value: "", label: "Commercial Real Estate" },
  { value: "", label: "Real Estate Agents and Brokers" },
  { value: "", label: "Activities Related to Real Estate" },
  {
    value: "",
    label: "Automotive and Automotive Equipment Rental and Leasing",
  },
  { value: "", label: "Consumer Goods Rental" },
  { value: "", label: "General Rental Centers" },
  {
    value: "",
    label:
      "Commercial and Industrial Machinery and Equipment Rental and Leasing",
  },
  { value: "", label: "Lessors of Nonfinancial Intangible Assets" },
  { value: "", label: "Real Estate and Rental & Leasing" },
  { value: "", label: "Retail Trade" },
  { value: "", label: "Automobile Dealers" },
  { value: "", label: "Other Motor Vehicle Dealers" },
  { value: "", label: "Automotive Parts, Accessories, and Tire Stores" },
  { value: "", label: "Furniture and Home Furnishings Stores" },
  { value: "", label: "Electronics and Appliance Stores" },
  {
    value: "",
    label:
      "Building Materials, Hardware Stores and Garden Equipment and Supplies Dealers",
  },
  { value: "", label: "Grocery, Food, and Beverage Stores" },
  { value: "", label: "Health and Personal Care Stores" },
  { value: "", label: "Gasoline Stations" },
  { value: "", label: "Clothing and Clothing Accessories Stores" },
  { value: "", label: "Sporting Goods, Hobby, Book, and Music Stores" },
  { value: "", label: "General Merchandise Stores" },
  { value: "", label: "Miscellaneous Store Retailers" },
  { value: "", label: "Nonstore Retailers" },
  { value: "", label: "eCommerce and Mail-Order Retail" },
  { value: "", label: "Retail Trade" },
  { value: "", label: "Transportation and Warehousing" },
  { value: "", label: "Airlines & Aviation" },
  { value: "", label: "Rail Transportation" },
  { value: "", label: "Water Transportation" },
  { value: "", label: "Trucking & Freight Transportation" },
  { value: "", label: "Transit and Ground Passenger Transportation" },
  { value: "", label: "Scenic and Sightseeing Transportation" },
  { value: "", label: "Support Activities for Transportation" },
  { value: "", label: "Transportation Logistics Services" },
  { value: "", label: "Postal Service, Couriers & Messengers" },
  { value: "", label: "Warehousing and Storage" },
  { value: "", label: "Transportation and Warehousing" },
  { value: "", label: "Wholesale Trade" },
  {
    value: "",
    label: "Automobile and Auto Parts & Supplies Merchant Wholesalers",
  },
  { value: "", label: "Furniture and Home Furnishing Merchant Wholesalers" },
  {
    value: "",
    label: "Lumber and Other Construction Materials Merchant Wholesalers",
  },
  {
    value: "",
    label:
      "Professional and Commercial Equipment and Supplies Merchant Wholesalers",
  },
  {
    value: "",
    label: "Metal and Mineral (except Petroleum) Merchant Wholesalers",
  },
  {
    value: "",
    label: "Consumer Electronics & Household Appliances Merchant Wholesalers",
  },
  { value: "", label: "Plumbing and HVAC Equipment Merchant Wholesalers" },
  {
    value: "",
    label: "Machinery, Equipment, and Supplies Merchant Wholesalers",
  },
  { value: "", label: "Miscellaneous Durable Goods Merchant Wholesalers" },
  { value: "", label: "Paper and Paper Product Merchant Wholesalers" },
  { value: "", label: "Drugs and Druggists' Sundries Merchant Wholesalers" },
  {
    value: "",
    label: "Apparel, Piece Goods, and Notions Merchant Wholesalers",
  },
  { value: "", label: "Grocery and Related Product Merchant Wholesalers" },
  { value: "", label: "Farm Product Raw Material Merchant Wholesalers" },
  { value: "", label: "Chemical and Allied Products Merchant Wholesalers" },
  {
    value: "",
    label: "Petroleum and Petroleum Products Merchant Wholesalers",
  },
  {
    value: "",
    label: "Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers",
  },
  { value: "", label: "Miscellaneous Nondurable Goods Merchant Wholesalers" },
  { value: "", label: "Wholesale Electronic Markets and Agents and Brokers" },
  { value: "", label: "Wholesale Trade" },
];

//Level
export const levelsData = [
  { value: "", label: "Board Members" },
  { value: "", label: "C-level Executives" },
  { value: "", label: "Vice Presidents" },
  { value: "", label: "Directors" },
  { value: "", label: "Managers" },
  { value: "", label: "Key Influencers" },
];

//Level
export const FinancesData = [
  { value: "", label: "Accounting & Reporting" },
  { value: "", label: "Accounts Payable (AP)" },
  { value: "", label: "Accounts Receivable (AR..." },
  { value: "", label: "Audit & Control" },
  { value: "", label: "Budgeting" },
  { value: "", label: "Collections" },
  { value: "", label: "Contract & Document Man..." },
  { value: "", label: "Financial Consolidation..." },
  { value: "", label: "Financial Planning & An..." },
  { value: "", label: "Cross Fixed Asset Systems" },
  { value: "", label: "Investor Relations (IR)" },
  { value: "", label: "Mergers & Acquisitions..." },
  { value: "", label: "Payments" },
  { value: "", label: "Payroll, Benefits & Com..." },
  { value: "", label: "Project Management 9" },
  { value: "", label: "Tax Provisioning & Comp..." },
  { value: "", label: "Vendor Management 9" },
];

//Hr Dara
export const hrsData = [
  { value: "", label: "Career Planning and Dev..." },
  { value: "", label: "Diversity & Inclusion" },
  { value: "", label: "Employee Relations" },
  { value: "", label: "HR Operations" },
  { value: "", label: "HRIS 2" },
  { value: "", label: "Learning Management & e..." },
  { value: "", label: "Orientation & Onboardin..." },
  { value: "", label: "Payroll, Benefits & Com..." },
  { value: "", label: "Performance Management" },
  { value: "", label: "Project Management" },
  { value: "", label: "Staffing" },
  { value: "", label: "Vendor Management" },
];

//IT Data
export const itsData = [
  { value: "", label: "Analytics & Business In..." },
  { value: "", label: "Communications & Mobili..." },
  { value: "", label: "CRM 2" },
  { value: "", label: "Customer Service, Sales..." },
  { value: "", label: "Databases & Data Storag..." },
  { value: "", label: "DevOps 2" },
  { value: "", label: "Digital Transformation..." },
  { value: "", label: "eCommerce" },
  { value: "", label: "Engineering Software &..." },
  { value: "", label: "Enterprise Software & A..." },
  { value: "", label: "Financial Consolidation..." },
  { value: "", label: "Hardware" },
  { value: "", label: "Help Desk" },
  { value: "", label: "HRIS 2" },
  { value: "", label: "Industry Specific Techn..." },
  { value: "", label: "Information Security" },
  { value: "", label: "Infrastructure, Cloud H..." },
  { value: "", label: "Inventory Tracking" },
  { value: "", label: "IT Asset Management (IT..." },
  { value: "", label: "IT Compliance & IT Audi..." },
  { value: "", label: "IT Enterprise Architect..." },
  { value: "", label: "IT Operations" },
  { value: "", label: "IT Service Management (..." },
  { value: "", label: "IT Strategy" },
  { value: "", label: "IT Training" },
  { value: "", label: "Machine Learning & Arti..." },
  { value: "", label: "Mobile Design" },
  { value: "", label: "Multimedia & Video" },
  { value: "", label: "Network Management" },
  { value: "", label: "Open Source" },
  { value: "", label: "Product Development & L..." },
  { value: "", label: "Program Management" },
  { value: "", label: "Project Management" },
  { value: "", label: "Quality Assurance" },
  { value: "", label: "SEO & Demand Generation" },
  { value: "", label: "Software & Applications..." },
  { value: "", label: "Supply Chain & Logistic..." },
  { value: "", label: "Testing & Performance" },
  { value: "", label: "Vendor Management" },
  { value: "", label: "Web Development & User..." },
  { value: "", label: "Website Design" },
];

//legals Data
export const legalsData = [
  { value: "", label: "Audit" },
  { value: "", label: "Case Management" },
  { value: "", label: "Dispute Resolution" },
  { value: "", label: "Governance, Risk & Comp..." },
  { value: "", label: "Government Relations & ..." },
  { value: "", label: "Intellectual Property" },
  { value: "", label: "Legal Operations" },
  { value: "", label: "Legal Practice" },
  { value: "", label: "Legal Research & eDisco..." },
  { value: "", label: "Privacy Law" },
  { value: "", label: "Project Management" },
  { value: "", label: "Vendor Management" },
];

//Marketing Data
export const marketingsData = [
  { value: "", label: "Account Based Marketing..." },
  { value: "", label: "Analytics & Business In..." },
  { value: "", label: "Brand & Product Managem..." },
  { value: "", label: "Creative/Content" },
  { value: "", label: "Customer Service, Sales..." },
  { value: "", label: "Digital Advertising" },
  { value: "", label: "eCommerce" },
  { value: "", label: "Field & Direct Marketin..." },
  { value: "", label: "Imaging/Graphics" },
  { value: "", label: "Lead Generation & Manag..." },
  { value: "", label: "Marketing Communication..." },
  { value: "", label: "Marketing Operations (M..." },
  { value: "", label: "Mobile Design" },
  { value: "", label: "Multimedia & Video" },
  { value: "", label: "Project Management" },
  { value: "", label: "Revenue Operations (Rev..." },
  { value: "", label: "SEO & Demand Generation" },
  { value: "", label: "Social" },
  { value: "", label: "Strategic Communication..." },
  { value: "", label: "Technical Marketing" },
  { value: "", label: "Vendor Management" },
  { value: "", label: "Webinars & Events" },
  { value: "", label: "Website Design" },
];

//Operations Data
export const operationData = [
  { value: "", label: "Clinical Systems, Healt..." },
  { value: "", label: "Compliance" },
  { value: "", label: "Construction Management" },
  { value: "", label: "Contact Center & Custom..." },
  { value: "", label: "Contact Center & Customer Service" },
  { value: "", label: "Corporate Strategy" },
  { value: "", label: "Environmental Health & ..." },
  { value: "", label: "Facilities Management" },
  { value: "", label: "HVAC, Electric, Heating..." },
  { value: "", label: "Inventory Tracking" },
  { value: "", label: "Medical & Health Operat..." },
  { value: "", label: "Occupational Health and..." },
  { value: "", label: "Office Management" },
  { value: "", label: "Physical Security" },
  { value: "", label: "Project Management" },
  { value: "", label: "Real Estate & Leasing" },
  { value: "", label: "Retail & Store Operatio..." },
  { value: "", label: "Shipping & Receiving" },
  { value: "", label: "Supply Chain & Logistic..." },
  { value: "", label: "Transportation" },
  { value: "", label: "Vendor Management" },
  { value: "", label: "Waste Management" },
];

//Procurements Data
export const procurementsData = [
  { value: "", label: "Business Process Manage..." },
  { value: "", label: "Offshore Outsourcing" },
  { value: "", label: "Onshore Outsourcing" },
  { value: "", label: "Project Management" },
  { value: "", label: "Strategic Sourcing" },
  { value: "", label: "Vendor Management" },
];

//rds Data
export const rdsData = [
  { value: "", label: "Applied Research" },
  { value: "", label: "Chemical, Pharmaceutica" },
  { value: "", label: "DevOps" },
  { value: "", label: "Digital Transformation..." },
  { value: "", label: "Engineering Software & ..." },
  { value: "", label: "Industrial Engineering ..." },
  { value: "", label: "Laboratory Devices" },
  { value: "", label: "Machine Learning & Arti..." },
  { value: "", label: "Materials Management" },
  { value: "", label: "Medical Devices" },
  { value: "", label: "Product Development & L..." },
  { value: "", label: "Program Management" },
  { value: "", label: "Project Management" },
  { value: "", label: "Quality Assurance" },
  { value: "", label: "Quantitative/Qualitativ..." },
  { value: "", label: "Technology Operations" },
  { value: "", label: "Testing & Performance" },
  { value: "", label: "Vendor Management" },
];

//Sales Data
export const saleData = [
  { value: "", label: "FinAccount Managementance" },
  { value: "", label: "CRM" },
  { value: "", label: "Customer Experience Man..." },
  { value: "", label: "Customer Service, Sales..." },
  { value: "", label: "Field Sales" },
  { value: "", label: "Forecasting and Plannin..." },
  { value: "", label: "Inside Sales & Business..." },
  { value: "", label: "Lead Generation & Manag..." },
  { value: "", label: "Partnerships & Channel..." },
  { value: "", label: "Project Management" },
  { value: "", label: "Revenue Operations (Rev..." },
  { value: "", label: "Sales Enablement" },
  { value: "", label: "Sales Engineering" },
  { value: "", label: "Sales Operations (Sales..." },
  { value: "", label: "Sales Training" },
  { value: "", label: "Vendor Management" },
];

//category Data
export const categoriesData = [
  { value: "", label: "Advertising" },
  { value: "", label: "Ad Exchanges" },
  { value: "", label: "Advertiser Campaign Management" },
  { value: "", label: "Data Management Platforms (DMP)" },
  { value: "", label: "Other Advertising Software" },
  { value: "", label: "Publisher Ad Management Software" },
  { value: "", label: "Video Advertising Platforms" },

  { value: "", label: "Analytics" },
  { value: "", label: "Business Intelligence (BI)" },
  { value: "", label: "Enterprise Performance Management (EPM)" },
  { value: "", label: "Enterprise Search" },
  { value: "", label: "Monitoring & Analytics Tools" },
  { value: "", label: "Other Analytics" },
  { value: "", label: "Predictive Analytics" },
  { value: "", label: "Reporting Tools" },
  { value: "", label: "Web Analytics" },

  { value: "", label: "Collaboration and Productivity" },
  { value: "", label: "Audio and Video Conferencing" },
  { value: "", label: "Email Software" },
  { value: "", label: "Other Collaboration and Productivity" },
  { value: "", label: "Productivity / Office Tools" },
  { value: "", label: "Team Collaboration and Business IM" },

  { value: "", label: "Commerce" },
  { value: "", label: "E-Commerce Platforms (ECP)" },
  { value: "", label: "Other Commerce" },
  { value: "", label: "Payment Processing" },
  { value: "", label: "Points of Sales (POS)" },
  { value: "", label: "Shopping Carts" },

  { value: "", label: "Communication" },
  { value: "", label: "Communications Management" },
  { value: "", label: "Other Communication tools" },
  { value: "", label: "VoIP" },

  { value: "", label: "Content Management" },
  { value: "", label: "Content Delivery Networks (CDN)" },
  { value: "", label: "Domain Name Services (DNS)" },
  { value: "", label: "Enterprise Content Management (ECM)" },
  { value: "", label: "Mapping and GIS" },
  { value: "", label: "Online Form Builders" },
  { value: "", label: "Online Video Platforms (OVP)" },
  { value: "", label: "Other Content Management" },
  { value: "", label: "Web Content Management Systems (WCMS)" },
  { value: "", label: "Web Hosting" },
  { value: "", label: "Website Builders" },

  { value: "", label: "Customer Service" },
  { value: "", label: "Contact Center Solutions" },
  { value: "", label: "Field Service Management (FSM)" },
  { value: "", label: "Help Desk" },
  { value: "", label: "Live Chats" },
  { value: "", label: "Other Customer Service" },

  { value: "", label: "Data Management" },
  { value: "", label: "API Management" },
  { value: "", label: "Big Data Processing" },
  { value: "", label: "Data Archiving, Backup & Recovery" },
  { value: "", label: "Data Integration" },
  { value: "", label: "Data Storage" },
  { value: "", label: "Data integration" },
  { value: "", label: "Database Management & Administration" },
  { value: "", label: "Databases" },
  { value: "", label: "Electronic Data Interchange (EDI)" },
  { value: "", label: "Electronic Document Management Systems (EDMS)" },
  { value: "", label: "Other Data Management" },

  { value: "", label: "Design" },
  { value: "", label: "Computer Aided Design (CAD)" },
  { value: "", label: "Design and Publishing" },
  { value: "", label: "Multimedia Design" },
  { value: "", label: "Other Design" },
  { value: "", label: "Visualization" },

  { value: "", label: "DevOps" },
  { value: "", label: "DevSecOps" },

  { value: "", label: "Emerging Technologies" },
  { value: "", label: "Artificial Intelligence & Machine Learning" },
  { value: "", label: "Blockchain" },
  { value: "", label: "Internet of Things" },
  { value: "", label: "Other Emerging Technologies" },

  { value: "", label: "Finance and Operations" },
  { value: "", label: "Accounting Software" },
  { value: "", label: "Accounts Payable" },
  { value: "", label: "Accounts Receivable and Billing" },
  { value: "", label: "Asset Management" },
  { value: "", label: "Business Process Management (BPM)" },
  { value: "", label: "Enterprise Resource Planning (ERP)" },
  { value: "", label: "Governance, Risk and Compliance (GRC)" },
  { value: "", label: "Inventory Management" },
  { value: "", label: "Order Management" },
  { value: "", label: "Other Finance and Operations" },
  { value: "", label: "Procurement" },
  { value: "", label: "Project Management" },
  { value: "", label: "Supplier Relationship Management (SRM)" },
  { value: "", label: "Supply Chain Management (SCM)" },
  { value: "", label: "Travel and Expense Management" },

  { value: "", label: "Hardware" },
  { value: "", label: "Data Storage Hardware" },
  { value: "", label: "Mainframe Computers" },
  { value: "", label: "Midrange Computers" },
  { value: "", label: "Network Hardware" },
  { value: "", label: "Other Hardware" },
  { value: "", label: "Personal Computers" },
  { value: "", label: "Printers and Copiers" },
  { value: "", label: "Servers" },
  { value: "", label: "Human Resources" },
  { value: "", label: "Case Management" },

  { value: "", label: "Human Resource Management Systems (HRMS)" },
  { value: "", label: "Learning Management Systems (LMS)" },
  { value: "", label: "Other Human Resources" },
  { value: "", label: "Payroll and Benefit Management" },
  { value: "", label: "Talent Management" },
  { value: "", label: "Time Tracking" },
  { value: "", label: "Workforce Management" },

  { value: "", label: "IT Infrastructure / Management" },
  { value: "", label: "Application Servers" },
  { value: "", label: "Cloud Infrastructure Management" },
  { value: "", label: "Disaster Recovery (DR)" },
  { value: "", label: "Enterprise Mobility Management" },
  { value: "", label: "IT Operations Management" },
  { value: "", label: "IT Resource Management" },
  { value: "", label: "IT Service Management (ITSM)" },
  { value: "", label: "Infrastructure as a Service (IaaS)" },
  { value: "", label: "Mobile Device Management" },
  { value: "", label: "Network Management" },
  { value: "", label: "Operating Systems" },
  { value: "", label: "Other IT Infrastructure / Management" },
  { value: "", label: "Platform as a Service (PaaS)" },
  { value: "", label: "Remote Desktop" },
  { value: "", label: "Server Management" },
  { value: "", label: "Virtualization" },
  { value: "", label: "Workflow Automation" },

  { value: "", label: "Industry Specific Software" },
  { value: "", label: "Academic/Education Software" },
  { value: "", label: "Construction Management" },
  { value: "", label: "Energy, Utilities, Oil and Gas" },
  { value: "", label: "Engineering Management" },
  { value: "", label: "Environmental Health and Safety Management" },
  { value: "", label: "Financial Services and Insurance" },
  { value: "", label: "Healthcare Management" },
  { value: "", label: "Hospitality and Food" },
  { value: "", label: "Industry Specific Software" },
  { value: "", label: "Legal Management" },
  { value: "", label: "Life Sciences / Pharmaceutical" },
  { value: "", label: "Manufacturing" },
  { value: "", label: "Media & Entertainment Software" },
  { value: "", label: "Non Profit" },
  { value: "", label: "Other Industry Specific Software" },
  { value: "", label: "Real Estate Management" },
  { value: "", label: "Transportation and Warehousing" },
  { value: "", label: "Transportation and warehousing" },

  { value: "", label: "Marketing" },
  { value: "", label: "ABM Software" },
  { value: "", label: "Content Marketing" },
  { value: "", label: "Customer Data Platform" },
  { value: "", label: "Demand Generation" },
  { value: "", label: "Email Marketing" },
  { value: "", label: "Feedbacks and Reviews" },
  { value: "", label: "Marketing Automation" },
  { value: "", label: "Marketing Performance Management" },
  { value: "", label: "Other Marketing" },
  { value: "", label: "Personalization" },
  { value: "", label: "Search Engine Optimization (SEO)" },
  { value: "", label: "Social Media Marketing" },

  { value: "", label: "Product Engineering and Development" },
  { value: "", label: "Application Development Tools" },
  { value: "", label: "Configuration Management" },
  { value: "", label: "Container Orchestration" },
  { value: "", label: "Continuous Integration and Delivery" },
  { value: "", label: "Error and Exception Monitoring" },
  { value: "", label: "Frameworks and Libraries" },
  { value: "", label: "Integrated Development Environments (IDE)" },
  { value: "", label: "Log Management" },
  { value: "", label: "Message Queues" },
  { value: "", label: "Other Product Engineering and Development" },
  { value: "", label: "Product Lifecycle Management (PLM)" },
  { value: "", label: "Programming Languages" },
  { value: "", label: "Quality Assurance" },
  { value: "", label: "Source Code Management" },

  { value: "", label: "Sales" },
  { value: "", label: "Configure Price Quote" },
  { value: "", label: "Customer Relationship Management (CRM)" },
  { value: "", label: "Other Sales" },
  { value: "", label: "Partner Relationship Management (PRM)" },
  { value: "", label: "Sales Acceleration" },
  { value: "", label: "Sales Analytics" },
  { value: "", label: "Sales Intelligence" },

  { value: "", label: "Security" },
  { value: "", label: "Application Security & Testing" },
  { value: "", label: "Cloud & Cybersecurity" },
  { value: "", label: "DDoS and Bot Protection" },
  { value: "", label: "Data Security & Data Loss Prevention (DLP)" },
  { value: "", label: "Data Security & Data Loss Prevention (DLP)" },
  { value: "", label: "Endpoint Protection" },
  { value: "", label: "IT Security" },
  { value: "", label: "Identity and Access Management (IAM)" },
  { value: "", label: "Network Security" },
  { value: "", label: "Other Security" },
  { value: "", label: "SSL Certificates" },
  { value: "", label: "Security Information and Event Management (SIEM)" },
  { value: "", label: "Web Firewalls" },
];

export const companyLists = [
  {
    id: "1",
    company: "Amazon.com",
    industry: "Advertising",
    revenue: "-",
    size: "-",
    hq: "US",
    verified: "US",
    machine: "US",
  },
  {
    id: "2",
    company: "Amazon.com",
    industry: "Advertising",
    revenue: "-",
    size: "-",
    hq: "US",
    verified: "US",
    machine: "US",
  },
  {
    id: "3",
    company: "Amazon.com",
    industry: "Advertising",
    revenue: "-",
    size: "-",
    hq: "US",
    verified: "US",
    machine: "US",
  },
  {
    id: "4",
    company: "Amazon.com",
    industry: "Advertising",
    revenue: "-",
    size: "-",
    hq: "US",
    verified: "US",
    machine: "US",
  },
  {
    id: "5",
    company: "Amazon.com",
    industry: "Advertising",
    revenue: "-",
    size: "-",
    hq: "US",
    verified: "US",
    machine: "US",
  },
];


export const contactLists = [
  {
    id: "1",
    name: "Preston Khanal",
    job_title: "Account Manager",
    level: "Key Influencer",
    dept: "Sales",
    company: "Amazon",
    location: "Seattle, TXUS",
  },
  {
    id: "2",
    name: "Preston Khanal",
    job_title: "Account Manager",
    level: "Key Influencer",
    dept: "Sales",
    company: "Amazon",
    location: "Seattle, TXUS",
  },
  {
    id: "3",
    name: "Preston Khanal",
    job_title: "Account Manager",
    level: "Key Influencer",
    dept: "Sales",
    company: "Amazon",
    location: "Seattle, TXUS",
  },
  {
    id: "4",
    name: "Preston Khanal",
    job_title: "Account Manager",
    level: "Key Influencer",
    dept: "Sales",
    company: "Amazon",
    location: "Seattle, TXUS",
  },
  {
    id: "5",
    name: "Preston Khanal",
    job_title: "Account Manager",
    level: "Key Influencer",
    dept: "Sales",
    company: "Amazon",
    location: "Seattle, TXUS",
  },
];
