import React, { useEffect, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import Prism from "prismjs";
import {
  Button,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
  Table,
  Form,
  Pagination,
  ButtonGroup,
  Modal,
  Tab,
  Tabs,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import Select from "react-select";
import { topics } from "../../staticData";

function IntentSearch(props) {
  const [value, setValue] = useState(50);
  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const topicData = topics;

  const sideSec = {
    height: "450px",
    overflowY: "auto",
  };
  return (
    <React.Fragment>
      <Row className="mb-3">
        <label>
          <b>Intent</b>
        </label>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Topics</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1" style={sideSec}>
              <Form.Control
                type="text"
                id="searchbox"
                placeholder="Filter by topic"
              />
              <br />
              <Form.Check type="checkbox" label="Select All" />
              {topicData?.map((data, index) => {
                return (
                  <Form.Check
                    type="checkbox"
                    value={data?.value}
                    label={data?.label}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Score Threshold</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>
                Minimum score for the selected topics a company must have. The
                higher the score, the more reliable the intent data.
              </p>
              <Form.Range min={0} max={60} />
              60
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Topic Threshold</Accordion.Header>
            <Accordion.Body className="ps-1 pe-1">
              <p>Minimum number of topics that domains are surging on.</p>
              <Form.Range min={0} max={60} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    </React.Fragment>
  );
}

export default IntentSearch;
